import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
  public passwordForm: FormGroup;
  public submitted = false;
  public showSpinner: boolean = false;
  public ErrorMessage: any;
  public SuccessMessage: any;
  
  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router) { }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
    });
  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
    this.submitted = true;
    var url = ''
    if (this.passwordForm.invalid) {
        return;
    }
    else{
      var formData = new FormData();
      formData.append('email', this.passwordForm.value.email);
      this.showSpinner = true;
      this.service.forgetPassword(formData).subscribe((response : any) =>{
        if(response.status == 1){
          this.showSpinner = false;
          this.SuccessMessage = response.message;
          let sucessMEssageModal: HTMLElement = document.getElementById('successMessage_forget_pass') as HTMLElement; 
          sucessMEssageModal.click();
        }
        else{
          this.passwordForm.reset();
          this.submitted = false;
          this.showSpinner = false;
          this.ErrorMessage = response.message;
          let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_forget_pass') as HTMLElement; 
          ErrorMEssageModal.click();
        }
      })
    }
  }

}
