import { Injectable, Inject } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router,NavigationExtras, NavigationEnd } from "@angular/router";

import { User } from '../shared/user';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class UserService {

//declare the global varible here

// Development Server URL
// baseUrl: string = 'http://192.168.1.160/intellisqr/5dfc641d139f0/webservices/';


// Live Server URL
// baseUrl: string = 'https://intellisqr.consagous.co.in/admin/webservices/';
// baseUrl: string = 'https://admin.intellisqr.com/webservices/';
// baseUrl: string = 'https://intellisqr.sapphiresolutions.in.net/admin/webservices/';
// baseUrl: string = 'http://10.10.2.141/intellisqr/webservices/';
//baseUrl: string = 'https://intellisqr.sapphiresolutions.in.net/admin/webservices/';
baseUrl: string = 'https://admin.intellisqr.com/webservices/';

// Client Live server URL
// baseUrl: string = 'http://www.intellisqr.com/5dfc641d139f0/webservices/';

 
 private currentUser      : any = {};   
 private loggedUserObject : any = {};
 private subject = new Subject<any>();

 
constructor(
  @Inject(DOCUMENT) private doc,
  public http : HttpClient, public toastr: ToastrManager, private router: Router) {
  this.router.events.forEach((event) => {
    if(event instanceof NavigationEnd) {
      this.currentUser = JSON.parse(localStorage.getItem('userData'));
    }})
 }

 CurrrentLoggedinUser(){  
    //  return JSON.parse(localStorage.getItem('user-detail'));
 }

  // Http Options
  httpOptions = {
    // headers: new HttpHeaders({
    //  'Content-Type' : 'application/json',
    //  'Cache-Control': 'no-cache'
    // 'device_type':'browser',
    // 'device_id':'',
    // 'current_time_zone': '',
    // 'language':'',
    // 'version':'1.0.0',
    // 'lat':'',
    // 'long':''
    // })
  }  


  // Http Options
  getHeadersLogin(){
    return {
    headers: new HttpHeaders({
    // 'Content-Type' : 'application/json, text/plain, */*',
    // 'Authorization':'eyJ1c2VyaWQiOiIxIiwidG9rZW4iOiI2MDlhZjg2ZmUxNTIzIn0=',
    // 'Authorization': this.currentUser.Authorization,
    'device_type':'browser',
    'device_id':'',
    'current_time_zone': '',
    'language':'',
    'version':'1.0.0',
    'lat':'',
    'long':''
    })
  };
}

getHeadersLoginPost2() {
  return {
    headers: new HttpHeaders({
    'version':'1.0.0',
    })
  };
}

getHeadersLoginPost() {
return { headers : new HttpHeaders({
  'Host': 'intellisqr.abcxyz.in',
  'Connection': 'keep-alive',
  'Content-Length': '432',
  'sec-ch-ua': '"Not A;Brand";v="99", "Chromium";v="90", "Google Chrome";v="90"',
  'device_id': '',
  'language': '',
  'device_type': 'browser',
  'sec-ch-ua-mobile': '?0',
  'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.93 Safari/537.36',
  'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryM02DwsND0kv8chdB',
  'Accept': 'application/json, text/plain, */*',
  'lat': '',
  'long': '',
  'current_time_zone': '',
  'version': '1.0.0',
  'Origin': ' https://intellisqr.abcxyz.in/',
  'Sec-Fetch-Site': 'same-site',
  'Sec-Fetch-Mode': 'cors',
  'Sec-Fetch-Dest': 'empty',
  'Referer': ' https://intellisqr.abcxyz.in/',
  'Accept-Encoding': 'gzip, deflate, br',
  'Accept-Language': 'en-US,en;q=0.9',
})

}
}

getHeadersLoginNew(){
  return { headers : new HttpHeaders({
'Accept-Encoding': 'gzip, deflate, br',
'Accept-Language': 'en-US,en;q=0.9',
// 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary3oaTsPjZsI8zUzAA',
'version' : '1.0.0',
'current_time_zone':'',
'device_id':'',
'device_type': 'browser',
'language':'',
'lat': '',
'long':''
  })}



  // header.set('version','1.0.0');
  // console.log(header);
  // return {headers : header}
  // return { headers : new HttpHeaders({
  //   'version':'1.0.0',
  // })}
//   headers: new HttpHeaders({
//   // 'Content-Type' : 'application/json, text/plain, */*',
//   // 'Authorization': this.currentUser.Authorization,
//   // 'device_type':'browser',
//   // 'device_id':'',
//   // 'current_time_zone': '',
//   // 'language':'',
//   'Accept': 'application/json, text/plain, */*',
// 'Accept-Encoding': 'gzip, deflate'
// Accept-Language: en-US,en;q=0.9
// Connection: keep-alive
// Content-Length: 267
// Content-Type: multipart/form-data; boundary=----WebKitFormBoundaryN5nR6SnyOWZqoLbY
// Host: intellisqr.abcxyz.in
// Origin: http://localhost:4200
// Referer: http://localhost:4200/
// User-Agent: Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.93 Safari/537.36
//   'version':'1.0.0',
//   // 'lat':'',
//   // 'long':''
//   })
// };
}

  // HttpClient API post() method => Create employee
  createUser(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/register', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }  

  // OTP verify
  otpVerify(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/verify_otp', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  // Resend OTP
  otpResend(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/resendOtp', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // User Login
  loginUser(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/login', data, this.getHeadersLoginNew())
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Forget Password
  forgetPassword(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/forgotPassword', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
  // New Password
  newPassword(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/setpassword', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Get Category
  getCategory(): Observable<User> {
    // return this.http.get<User>('https://jsonplaceholder.typicode.com/todos/1', this.httpOptions)
    return this.http.get<User>(this.baseUrl + 'api/getCategory')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  globalSearch(data): Observable<any> {
    return this.http.post<User>(this.baseUrl + 'api/search_record', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

    // Get banner
    getBanner(): Observable<User> {
      // return this.http.get<User>('https://jsonplaceholder.typicode.com/todos/1', this.httpOptions)
      return this.http.get<User>(this.baseUrl + 'api/fetch_banner')
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }


  // Get Industry
  getIndustry(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/getIndustry', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getDescription(): Observable<any> {
    
    /* return this.http.get<any>(this.baseUrl + 'api/get_home_description', this.httpOptions) */
    return this.http.get<any>(this.baseUrl + 'api/get_home_description', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }



  // Search Data
  searchData(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/search_data', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  likeDislike(data): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'api/update_like', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Solution Data
  getSolution(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/solution_details', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

    // Objective Data
    getObjective(data): Observable<User> {
      return this.http.post<User>(this.baseUrl + 'api/fetch_objective_details', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }

  // Get Faq Questions list
  getFAQquestions(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/faq_questions', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Submit FAQ Data
  sendFaqData(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/submit_faq_questions', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Get Privacy policy
  getPrivacyPolicy(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/get_privacy_policy', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Get Terms & condition
  getTermsCondition(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/get_terms_condition', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

   // Get Trending Solution
   getTrending(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/getTrendingSolutions', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  // Get blog list
  getBlog(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/fetch_blogs', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getTrendingBlog(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'api/fetch_trending_blogs', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

    // Get blog list
    getBlogDetails(data): Observable<any> {
      return this.http.post<any>(this.baseUrl + 'api/fetch_blog_details', data, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }


        // Get related blog list
        getRelatedBlog(data): Observable<any> {
          return this.http.post<any>(this.baseUrl + 'api/fetch_related_blogs', data, this.httpOptions)
          .pipe(
            retry(1),
            catchError(this.handleError)
          )
        }

         // Get compare solutions data
         getCompareSolution(data): Observable<any> {
          return this.http.post<any>(this.baseUrl + 'api/compare_records',data,  this.httpOptions)
          .pipe(
            retry(1),
            catchError(this.handleError)
          )
        }

 
 //successAlert for fire toast/...
 successAlert(msg :any) {
   this.toastr.successToastr(msg, ' ', {
     position: 'top-right'
   })
 }

  //successAlert for fire toast/...
  dangerAlert(msg :any) {
   this.toastr.errorToastr(msg, ' ', {
     position: 'top-right'
   })
 }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
 }

 postData(data): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'api/addRequest', data, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  //resetCurrentRouter for reload current router
  //if use is alerady stay on a same route.....
  resetCurrentRouter(){
    //used for reload current route for udate update...
    this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl).then(() => {
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
   });
  }




  sendNumber(number:number){
    this.subject.next({text:number});
  }

  getNumber():Observable<any>{
    return this.subject.asObservable();
  }

  // Get Meta Data
  getMetaData(data) {
    // return this.http.get<User>('https://jsonplaceholder.typicode.com/todos/1', this.httpOptions)
    // return this.http.post<any>('http://10.10.2.141/intellisqr/webservices/api/fetch_seotags',data)
    return this.http.post<any>(this.baseUrl+'api/fetch_seotags',data)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    // console.log("this.doc",this.doc)
    link.setAttribute('href', this.doc.URL);
  }
  
  getSearchData(searchdata) {
    return this.http.post<any>(this.baseUrl+'api/search_api',searchdata)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  getAllSubCategories(){
    return this.http.get<any>(this.baseUrl+'api/get_all_category')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  getCategorybyId(catId){
    return this.http.post<any>(this.baseUrl+'api/getsubcategory',catId)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  
}