import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
declare var $: any; 

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public callBackForm: FormGroup;
  public helpMeForm : FormGroup;
  public industryArray: any = [];
  public industryFinalArray  : any = [];
  public submitted:boolean           = false;
  public helpSubmitted:boolean       = false;
  public selectedIndustry    : any = {};
  public selectedSolution    : any;
  public industry: any = [];
  public industry_selected: any = '';
  public solution_selected: any = '';
  public ErrorMessage: any;
  public helpshowSpinner: boolean = false;
  public requestshowSpinner: boolean = false;
  public category: any = []

  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
  //initialize the form validations for callBackForm...
  this.callBackForm = this.formBuilder.group({
    name      : ['', Validators.required],
    email     : ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
    number    : ['', [Validators.required]],
    message   : ['', [Validators.required]]
  });

  //initialize the form validations for helpMeForm...
   this.helpMeForm = this.formBuilder.group({
    cname      : ['', [Validators.required]],
    cperson   : ['', [Validators.required]],
    email     : ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
    number    : ['', [Validators.required]],
    message   : ['', [Validators.required]],
    industry  : ['', [Validators.required]],
    // solution  : ['', [Validators.required]],
    category  : ['', [Validators.required]],
  });
  this.industryList();
  this.categoryList();

}

 get f() { return this.callBackForm.controls; }

 get g() { return this.helpMeForm.controls; }

 // Industry List
 industryList() {
  this.service.getIndustry().subscribe((response : any) =>{
    if(response.status == 1){
      this.industry = response.data;
    }})
}

openInNewPage(url) {
  let newUrl;
  if(url == 'fb'){
    newUrl = 'https://www.facebook.com/Intellisqr-101133975736855/'
  }
  if(url == 'ig'){
    newUrl = 'https://instagram.com/intellisqr?utm_medium=copy_link'
  }
  if(url == 'li'){
    newUrl = 'https://www.linkedin.com/company/intellisqr'
  }
  if(url == 'tw'){
    newUrl = 'https://twitter.com/intellisqr'
  }
  window.open(newUrl, "_blank")
}

  onSubmit(key : any) {
     //udpate data via form wise,....... 
    if(key == 'callBackForm') {
      this.submitted = true;
      if(this.callBackForm.invalid) {
          return;
      } 
      else if(!this.callBackForm.value.name.replace(/^\s+/g, '').length){
        this.callBackForm.value.name = ' ';
        return;
      }
      else if (!this.callBackForm.value.message.replace(/^\s+/g, '').length){
        this.callBackForm.value.message = ' ';
        return;
      }
      else {
         this.requestshowSpinner = true;
         var formData = new FormData();
         formData.append('contact_type','3');
         formData.append('user_name', this.callBackForm.value.name);
         formData.append('mobile', this.callBackForm.value.number);
         formData.append('email', this.callBackForm.value.email);
         formData.append('description', this.callBackForm.value.message);
         
         this.service.postData(formData).subscribe((response : any) =>{
          if(response.status == 1) {
          
            //used for dismiss current model open and another modal.......
           this.openModal('callBackForm');
           this.callBackForm.reset();
           this.requestshowSpinner = false;
           this.submitted = false;
         //   this.service.successAlert(response.message);
          } else{
            // this.service.dangerAlert(response.message);
            this.requestshowSpinner = false;
            this.ErrorMessage = response.message;
            let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_home') as HTMLElement; 
            ErrorMEssageModal.click();
          }
      })
    }
  } else {

    this.helpSubmitted = true;
     if(this.helpMeForm.invalid) {
        return;
      }
      else if(!this.helpMeForm.value.cname.replace(/^\s+/g, '').length){
        this.helpMeForm.value.cname = ' ';
        return;
      } 
      else if(!this.helpMeForm.value.cperson.replace(/^\s+/g, '').length){
        this.helpMeForm.value.cperson = ' ';
        return;
      }
      else if (!this.helpMeForm.value.message.replace(/^\s+/g, '').length){
        this.helpMeForm.value.message = ' ';
        return;
      }
      else {
         this.helpshowSpinner = true;
         var formData = new FormData();
         formData.append('contact_type','2');
         formData.append('user_name', this.helpMeForm.value.cperson);
         formData.append('email', this.helpMeForm.value.email);
         formData.append('description', this.helpMeForm.value.message);
         formData.append('mobile', this.helpMeForm.value.number);
         formData.append('company_name', this.helpMeForm.value.cname);
         formData.append('industry_id', this.helpMeForm.value.industry);
        //  formData.append('solution_id', this.helpMeForm.value.solution);
         formData.append('category_id', this.helpMeForm.value.category);

         //
         this.service.postData(formData).subscribe((response : any) =>{
          if(response.status == 1){
              //used for dismiss current model open and another modal.......
              this.openModal('');
              this.helpMeForm.reset();
              this.helpshowSpinner = false;
              this.industry_selected = '';
              this.solution_selected = '';
              this.helpSubmitted = false;
          //  this.service.successAlert(response.message);
          } else {
            // this.service.dangerAlert(response.message);
            this.helpshowSpinner = false;
            this.ErrorMessage = response.message;
            let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_home') as HTMLElement; 
            ErrorMEssageModal.click();
          }
      })
    }
  }
}

/*
* Category list
* @return Category List Array
*/
categoryList() {
  this.service.getCategory().subscribe((response : any) =>{
    if(response.status == 1){
      this.category = response.data;
    }})
}


// /closeModal for close modal.....
closeModal(){
  document.getElementById('confirmatioModal').style.display = 'none';
  $('body').removeClass("modal-open");
  $('.modal-backdrop').hide();
  $('body').css("padding-right", '0');
  this.service.resetCurrentRouter();
}

//openModal for open modal after response is generated from API.....
openModal(key : any){
    if(key == 'callBackForm') {
      $('#myModal').hide();
      $('.modal-backdrop').hide();
    } else {
      $('#helpMeForm').hide();
      $('.modal-backdrop').hide();
    }
    let element1: HTMLElement = document.getElementById('showThanksPopup') as HTMLElement; 
    element1.click();
}

updateValueByKey(value :any, key : any){
  if(key == 'industry') {
    this.selectedIndustry = value;  
  } else {
    this.selectedSolution = value;  
  }
  
}



}

