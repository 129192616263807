import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
declare var $: any; 
import { Meta, Title } from '@angular/platform-browser';  

@Component({
  selector: 'app-consult',
  templateUrl: './consult.component.html',
  styleUrls: ['./consult.component.css']
})
export class ConsultComponent implements OnInit {
  helpMeForm:FormGroup
  public helpSubmitted:boolean= false;
  submitted: boolean;
  callBackForm: FormGroup;
  public helpshowSpinner: boolean = false;
  public requestshowSpinner: boolean = false;
  public industry_selected: any = '';
  public solution_selected: any = '';
  ErrorMessage: any;
  selectedIndustry: any={};
  selectedSolution: any;
  industry: any=[];
  category: any=[];

  constructor(private formBuilder: FormBuilder, 
    private service: UserService, 
    private router: Router, 
    private activeRoute: ActivatedRoute,
    private meta: Meta,
    private pageTitle: Title, 
    )
     { 
       
     }

  ngOnInit() {
    //initialize the form validations for callBackForm...
  this.callBackForm = this.formBuilder.group({
    name      : ['', Validators.required],
    email     : ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
    number    : ['', [Validators.required]],
    message   : ['', [Validators.required]]
  });
  
     //initialize the form validations for helpMeForm...
   this.helpMeForm = this.formBuilder.group({
    cname      : ['', [Validators.required]],
    cperson   : ['', [Validators.required]],
    email     : ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
    number    : ['', [Validators.required]],
    message   : ['', [Validators.required]],
    industry  : ['', [Validators.required]],
    // solution  : ['', [Validators.required]],
    // category  : ['', [Validators.required]],
  });
  this.industryList();
  this.categoryList();
  this.pageTitle.setTitle("Free consultation with our Best Customer Analytics Experts | Intellisqr");
  this.meta.addTags([
    { name: 'description', content: 'Free Consultation with our analytics experts to help you understand different business analytics software and help you pick the right one' },
    { name: 'keywords', content: 'Best Customer Analytics, Best Customer Data platform, Best Customer Analytics tools' }  
  ]);  
  }

  // Industry List
 industryList() {
  this.service.getIndustry().subscribe((response : any) =>{
    console.log(response);
    
    if(response.status == 1){
      this.industry = response.data;
    }})
}
categoryList() {
  this.service.getCategory().subscribe((response : any) =>{
    if(response.status == 1){
      this.category = response.data;
    }})
}

  onSubmit(key : any) {
    //udpate data via form wise,....... 
   if(key == 'callBackForm') {
     this.submitted = true;
     if(this.callBackForm.invalid) {
         return;
     } 
     else if(!this.callBackForm.value.name.replace(/^\s+/g, '').length){
       this.callBackForm.value.name = ' ';
       return;
     }
     else if (!this.callBackForm.value.message.replace(/^\s+/g, '').length){
       this.callBackForm.value.message = ' ';
       return;
     }
     else {
        this.requestshowSpinner = true;
        var formData = new FormData();
        formData.append('contact_type','3');
        formData.append('user_name', this.callBackForm.value.name);
        formData.append('mobile', this.callBackForm.value.number);
        formData.append('email', this.callBackForm.value.email);
        formData.append('description', this.callBackForm.value.message);
        
        this.service.postData(formData).subscribe((response : any) =>{
         if(response.status == 1) {
         
           //used for dismiss current model open and another modal.......
          this.openModal('callBackForm');
          this.callBackForm.reset();
          this.requestshowSpinner = false;
          this.submitted = false;
        //   this.service.successAlert(response.message);
         } else{
           // this.service.dangerAlert(response.message);
           this.requestshowSpinner = false;
           this.ErrorMessage = response.message;
           let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_home123') as HTMLElement; 
           ErrorMEssageModal.click();
         }
     })
   }
 } else {

   this.helpSubmitted = true;
    if(this.helpMeForm.invalid) {
       return;
     }
     else if(!this.helpMeForm.value.cname.replace(/^\s+/g, '').length){
       this.helpMeForm.value.cname = ' ';
       return;
     } 
     else if(!this.helpMeForm.value.cperson.replace(/^\s+/g, '').length){
       this.helpMeForm.value.cperson = ' ';
       return;
     }
     else if (!this.helpMeForm.value.message.replace(/^\s+/g, '').length){
       this.helpMeForm.value.message = ' ';
       return;
     }
     else {
        this.helpshowSpinner = true;
        var formData = new FormData();
        formData.append('contact_type','2');
        formData.append('user_name', this.helpMeForm.value.cperson);
        formData.append('email', this.helpMeForm.value.email);
        formData.append('description', this.helpMeForm.value.message);
        formData.append('mobile', this.helpMeForm.value.number);
        formData.append('company_name', this.helpMeForm.value.cname);
        formData.append('industry_id', this.helpMeForm.value.industry);
       //  formData.append('solution_id', this.helpMeForm.value.solution);
        // formData.append('category_id', this.helpMeForm.value.category);

        //
        this.service.postData(formData).subscribe((response : any) =>{
         if(response.status == 1){
             //used for dismiss current model open and another modal.......
             this.openModal('');
             this.helpMeForm.reset();
             this.helpshowSpinner = false;
             this.industry_selected = '';
             this.solution_selected = '';
             this.helpSubmitted = false;
         //  this.service.successAlert(response.message);
         } else {
           // this.service.dangerAlert(response.message);
           this.helpshowSpinner = false;
           this.ErrorMessage = response.message;
           let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_home123') as HTMLElement; 
           ErrorMEssageModal.click();
         }
     })
   }
 }
}

  //openModal for open modal after response is generated from API.....
openModal(key : any){
  if(key == 'callBackForm') {
    $('#myModal').hide();
    $('.modal-backdrop').hide();
  } else {
    $('#helpMeForm').hide();
    $('.modal-backdrop').hide();
  }
  let element1: HTMLElement = document.getElementById('showThanksPopup') as HTMLElement; 
  element1.click();
}

updateValueByKey(value :any, key : any){
  if(key == 'industry') {
    this.selectedIndustry = value;  
  } else {
    this.selectedSolution = value;  
  }
  
}

 get g() { return this.helpMeForm.controls; }


}
