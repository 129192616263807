import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { qs} from 'qs'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-compare-solution',
  templateUrl: './compare-solution.component.html',
  styleUrls: ['./compare-solution.component.css']
})
export class CompareSolutionComponent implements OnInit {
  compareIds: any=[];
  item: any;
  obj: {};
  cmp1: any;
  cmp2: any;
  cmp3: any;
  compareDetail: any=[];
  relatedBlog: any=[];
  relateSolution: boolean;
  largeImage: any=[];
  selectImageone: any;
  // obj: { cmp1: number; cmp2: number; };

  constructor(private active:ActivatedRoute, 
    private service:UserService, 
    private route:Router,
    private spinner: NgxSpinnerService) { 
    this.active.queryParams.subscribe((params)=>{
      this.cmp1=params.cmp1;
      this.cmp2=params.cmp2;
      this.cmp3=params.cmp3;
      console.log('sdfc', this.cmp1);
    //  this.item= this.compareIds.map((ele)=>{
    //    this.obj={
    //      cmp:ele,
         
    //    }
    //     return this.obj
    //   })
    if(this.cmp1 && this.cmp2 ){
      this.item=[];
      this.item.push(this.cmp1,this.cmp2);
      this.obj={
        "cmp1":this.cmp1,
        "cmp2":this.cmp2

      }
    }
    if(this.cmp1 && this.cmp2 && this.cmp3){
      this.item=[];
      this.item.push(this.cmp1,this.cmp2);
      this.obj={
        "cmp1":this.cmp1,
        "cmp2":this.cmp2,
        "cmp3":this.cmp3

      }
    }
      console.log('dscsd', this.item, this.obj);
      
    });
    this.compareDetails();


  }

  ngOnInit() {
  }

  navigateToBlog(item) {
    console.log(item);
    let url,str; 
    url= `/blog-details/${item.id}/${item.name}`;
     str = url.split(' ').join('');
     setTimeout(() => {
      this.route.navigateByUrl(str);       
     }, 100);
  }

  imageView(data,i){
    this.largeImage=data;
    console.log('sds', this.largeImage,i);
    
  }
  
  selectedImage(image,i){
    this.selectImageone=image
  }
  crossData(){
    // this.largeImage=[]
    this.selectImageone=''
  }
  

  compareDetails(){
    // this.spinner.show()
    let formData = new FormData();
    formData.append('type_id_array' , JSON.stringify(this.obj))
    this.service.getCompareSolution(formData).subscribe((res:any)=>{
      console.log('sdfs',res);
      if(res.code==200){
        // this.spinner.hide()
        this.relateSolution=true
        this.relatedBlog=JSON.parse (localStorage.getItem('relatedBlog'))

        console.log('dfdg',this.relatedBlog);
        
        this.compareDetail=res.data;
        console.log('dfdg',this.compareDetail);
        this.compareDetail.forEach(element => {
          let arrData = [];
          if(element.features1){
            let data = element.features1;
            arrData = data.split('\r\n');
            element.firstCompare = arrData;
          }
          let newArrData = [];
          if(element.price){
            let data = element.price;
            newArrData = data.split('\r\n');
            element.newPricing = newArrData;
          }
          let newrData = [];
          if(element.support){
            let data = element.support;
            newrData = data.split('\r\n');
            element.newSupport = newrData;
          }
        });
        console.log('dfdg',this.compareDetail);
      }
      
    },(err)=>{
      this.service.handleError("something went wrong")
    })
  }

  goToSolution(data){
    // let id=compare.id,
    // let 
    // this.route.navigate(['solution-detail/${data.id}/${data.title}/${data.type}`'])
    let url,str; 
      url= `/solution-detail/solution/${data.id}/${data.title}`;
       str = url.split(' ').join('');
       setTimeout(() => {
        this.route.navigateByUrl(str);       
       }, 100);

       
  }

  goToWeb(data) {
    console.log('ddx', data);
    if(data && data.site_url){
      window.open(data.site_url, "_blank")

    }
    else{
      window.location.reload()
    }
  }

}
