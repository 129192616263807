import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  public showSignupbtn: boolean;
  constructor() { 
    if(localStorage.getItem('userData')){
      this.showSignupbtn = false;
    }
    else{
      this.showSignupbtn = true;
    }
  }

  ngOnInit() {
  }

}
