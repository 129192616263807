import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router, NavigationExtras, NavigationEnd } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { Meta, Title } from '@angular/platform-browser';
import { Item } from 'angular2-multiselect-dropdown';
declare var $: any;
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MetaService } from 'src/app/service/meta.service';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { debug } from 'console';
import { StringifyOptions } from 'querystring';

@Component({
  selector: 'app-browse-solutions',
  templateUrl: './browse-solutions.component.html',
  styleUrls: ['./browse-solutions.component.css']
})
export class BrowseSolutionsComponent implements OnInit, AfterViewInit {
  @ViewChild('stickyMenu', { static: false }) menuElement: ElementRef;
  sticky: boolean = false;
  elementPosition: any;
  public category: any = [];
  public industry: any = [];
  public category_text: any;
  public industry_text: any = 'Industry';
  public category_id: any;
  public industry_id: any;
  public page_no: any = 1;
  public searchForm: FormGroup;
  public currentListData: any;
  public currentList: any = {};
  public keywords: any = "";
  public keyword: any;
  public noSolutionDataFound: boolean = false;
  public userData: any;
  public industryArray: any = [];
  public industryFinalArray: any = [];
  public noSelectedDataFound: boolean = false;
  public allIndustrySelected: boolean = false;
  public dropdownSettings: any;
  public allIsSelected: boolean;
  public newcateArray: any = [];
  public unique: any = [];
  public submitted: boolean;
  public showSpinner: boolean = false;
  public isFirst: boolean = false;
  public notChecked: boolean = true
  public renderedBGuide: any = 0
  public dropMore: any = []
  public selectedSubcategory: any = []

  contactUsForm: FormGroup;
  read_more: boolean = true;
  solutionTabChanges: boolean = false;
  itemList = [];
  itemList1 = [];
  itemList2 = [];
  selectedItems = [];
  selectedItems1 = [];
  selectedItems2 = [];
  settings = {};
  settings1 = {};
  settings2 = {};
  searchResultSuggetiondiv: boolean = false;
  noSearchDataDiv = '';
  searchSolutionData = [];
  searchCategoryData = [];
  topCategoryData = [];
  relatedBlog: any = [];
  searchKeyword = 'name';
  @ViewChild('auto', { static: false }) auto;
  searchData = [
    //  {
    //    id: 1,
    //    name: ''
    //  },
    //  {
    //    id: 2,
    //    name: ''
    //  }
  ];
  selectedElement: any;
  compareData: any = [];
  comparepage: boolean = false;
  compareItem: any = [];
  cmp3: any;
  cmp2: any;
  cmp1: any;
  compareSelect: any = [];
  subCategoryFilter: any = [];
  priceFilter: any = [];
  deploymentFilter: any = [];
  BuyersGuide: any = [];
  loggedInUserData: any;
  isLike: number = 0;
  KeynewSearch: string;
  newSearch: string
  priceFilters: string
  deploy: string
  

  ngAfterViewInit(): void {
    if (this.menuElement && this.menuElement != null) this.elementPosition = this.menuElement.nativeElement.offsetTop;

  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      // this.sticky = true;
      if (windowScroll >= 1800) {
        this.sticky = false
      }
    } else {
      this.sticky = false;
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private meta: Meta,
    private pageTitle: Title,
    private spinner: NgxSpinnerService,
    private metaService: MetaService,
    private titleService: Title,
    private location: Location,
    @Inject(DOCUMENT) private dom
  ) {
    this.spinner.show();
    if (this.router.getCurrentNavigation() == null || this.router.getCurrentNavigation().extras.state == undefined) {

      if (localStorage.getItem('search-list-info')) {
        this.currentListData = JSON.parse(localStorage.getItem('search-list-info'));
        this.noSolutionDataFound = false;
      }
      else {
        this.noSolutionDataFound = true;
      }
    } else {
      this.currentListData = this.router.getCurrentNavigation().extras.state.searchListData;
      localStorage.setItem('search-list-info', <any>JSON.stringify(this.currentListData));
    }
    if (localStorage.getItem('userData')) {
      this.userData = localStorage.getItem('userData');
    }

  }

  expandMore(i) {
    this.dropMore[i] = !this.dropMore[i]
    this.read_more = !this.read_more;
  }

  selectEventClick() {
    // this.onChangeSearch(this.selectedElement,true);
    // this.selectEvent(this.selectedElement);
  }

  selectEvent(item) {
    // this.selectedElement = item;
    this.unique = [];
    let solution_list = [];
    let solutionlist = [];
    this.noSelectedDataFound = false;
    this.noSolutionDataFound = false;
    solution_list.push(item);
    if (item && item.solutiondata && item.solutiondata.length) {
      solutionlist = item.solutiondata;
    }
    this.unique.push({ detail_text: item && item.description ? item.description : item.about_us, name: item.title, title: item.title, type: item.type, file_name: item.file_name, site_url: item.site_url, id: item.id, solution_list: solution_list, solutiondata: solutionlist });
    // do something with selected item
  }

  breakString(s, l) {
    return s.split("")
  }

  getLength(b) {

    return 5 - b.split("").length
  }

  onChangeSearch(val: string, type, userId?) {
    this.selectedElement = val;
    var formData = new FormData();
    formData.append('keyword', val);
    if (this.loggedInUserData) {
      formData.append('user_id', this.loggedInUserData.user_id);
    }
    this.service.globalSearch(formData).subscribe((res: any) => {
      let arr = []
      if (res && res.data && res.data.objectives.length) {
        arr = [];
        this.itemList = [];
        this.itemList1 = [];
        this.itemList2 = [];
        this.BuyersGuide = [];
        res.data.objectives.forEach((element: any) => {
          element.name = element.title,
            element.type = 'objective'
          if (type && element.is_enabled_trending == 1) {
            this.selectEvent(element);
          }
          arr.push(element);
          element.solutiondata.forEach((ele: any) => {
            ele.name = ele.title
            ele.liked = false;
            ele.type = 'solution'
            ele.img_logo = ele.logo ? `${environment.imageUrl}/uploads/solution_logo/${ele.logo}` : 'https://intellisqr.abcxyz.in/uploads/blogs/1626972131771142CDP_banner.jpg'
            if (ele.subcategory_data && ele.subcategory_data.length) {
              ele.subcategory_data.forEach(element => {
                if (this.itemList.length == 0) {
                  this.itemList.push({ "id": element.id, "itemName": element.name, "buyerGuide": element.buyer_guide });
                  // if(element.buyer_guide)
                  // this.BuyersGuide.push({'SubCategoryName' : element.name, BuyerGuide : element.buyer_guide })
                } else {
                  let isExist = this.itemList.filter(item => item.itemName == element.name).length > 0;
                  if (isExist) {

                  } else {
                    this.itemList.push({ "id": element.id, "itemName": element.name, "buyerGuide": element.buyer_guide });
                    // if(element.buyer_guide)
                    // this.BuyersGuide.push({'SubCategoryName' : element.name, BuyerGuide : element.buyer_guide })
                  }
                  // if(this.itemList.filter())
                }
              });

            }

            if (ele.deployment) {
              if (this.itemList1.length == 0) {
                this.itemList1.push({ "id": ele.deployment, "itemName": ele.deployment });
              } else {
                let isExist = this.itemList1.filter(item => item.itemName == ele.deployment).length > 0;
                if (isExist) {

                } else {
                  this.itemList1.push({ "id": ele.deployment, "itemName": ele.deployment });
                }
                // if(this.itemList.filter())
              }
            }


            if (ele.price) {
              if (this.itemList2.length == 0) {
                this.itemList2.push({ "id": ele.price, "itemName": ele.price });
              } else {
                let isExist = this.itemList2.filter(item => item.itemName == ele.price).length > 0;
                if (isExist) {

                } else {
                  this.itemList2.push({ "id": ele.price, "itemName": ele.price });
                }
                // if(this.itemList.filter())
              }
            }


            // arr.push(ele);
          });
        });
        this.searchData = arr;
      }
    }, err => {
      console.log(err);
    })
  }

  onFocused(e) {
    // do something when input is focused
  }

  navigateToBlog(item) {
    let url, str;
    url = `/blog-details/${item.id}/${item.name}`;
    str = url.split(' ').join('');
    setTimeout(() => {
      this.router.navigateByUrl(str);
    }, 100);
  }

  ngOnInit() {
    this.loggedInUserData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : undefined
    // this.categoryList();
    this.industryList();
    this.getTopCategories();
    this.itemList = [
      { "id": 1, "itemName": "sub-category1" },
      { "id": 2, "itemName": "sub-category2" },
      { "id": 3, "itemName": "sub-category3" },
      { "id": 4, "itemName": "sub-category4" },
      { "id": 5, "itemName": "sub-category5" },
      { "id": 6, "itemName": "sub-category6" }
    ];

    this.itemList1 = [
      { "id": 11, "itemName": "Deployment1" },
      { "id": 21, "itemName": "Deployment2" },
      { "id": 31, "itemName": "Deployment3" },
      { "id": 41, "itemName": "Deployment4" },
      { "id": 51, "itemName": "Deployment5" },
      { "id": 61, "itemName": "Deployment6" }
    ];

    this.itemList2 = [
      { "id": 12, "itemName": "10$" },
      { "id": 22, "itemName": "20$" },
      { "id": 32, "itemName": "30$" },
      { "id": 42, "itemName": "40$" },
      { "id": 52, "itemName": "50$" },
      { "id": 62, "itemName": "60$" }
    ];

    this.selectedItems = [
      // { "id": 1, "itemName": "sub-category1" },
      // { "id": 2, "itemName": "sub-category2" },
      // { "id": 3, "itemName": "Australia" },
      // { "id": 4, "itemName": "Canada" }
    ];

    this.selectedItems1 = [
      // { "id": 1, "itemName": "Deployment1" },
      // { "id": 2, "itemName": "Deployment2" },
      // { "id": 3, "itemName": "Australia" },
      // { "id": 4, "itemName": "Canada" }
    ];

    this.selectedItems2 = [
      // { "id": 1, "itemName": "10$" },
      // { "id": 2, "itemName": "20$" },
      // { "id": 3, "itemName": "Australia" },
      // { "id": 4, "itemName": "Canada" }
    ];

    this.settings = {
      text: "Filter By Sub Category",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class"
    }

    this.settings1 = {
      text: "Filter By Deployment",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class"
    }

    this.settings2 = {
      text: "Filter By Pricing",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class"
    }

    this.formValidation()


    // if (this.currentListData) {
    //   this.currentList = this.currentListData.searchListArray;
    //   this.keywords = this.currentListData.formdata.keywords;


    //   /* start New Array for showing category once for respective solution */
    //   var newArray = [];
    //   for (var i = 0; i < this.currentList.solution_data.length; i++) {
    //     var data = {
    //       id: this.currentList.solution_data[i].category,
    //       name: this.currentList.solution_data[i].category_name,
    //       detail_text: this.currentList.solution_data[i].category_description
    //     }
    //     if (newArray.length > 0) {
    //       for (var j = 0; j < newArray.length; j++) {
    //       }
    //     }
    //     newArray.push(data)
    //   }
    //   this.unique = [];
    //   this.unique = Array.from(newArray.reduce((a, o) => a.set(o.id, o), new Map()).values());
    //   for (var l = 0; l < this.unique.length; l++) {
    //     this.unique[l].solution_list = [];
    //     this.unique[l].solutiondata = [];
    //     this.unique[l].type = 'solution';
    //   }
    //   this.currentList.solution_data.forEach((obj) => {
    //     obj.liked = false;
    //     obj.type = 'solution';
    //     var existNotification = this.unique.find(({ id }) => obj.category === id);
    //     if (existNotification) {
    //       var pp = this.unique.find(x => x.id == obj.category);
    //       pp.solution_list.push(obj)
    //       pp.solutiondata = [];
    //     }
    //   });
    //   /* End New Array for showing category once for respective solution */


    // }

    setTimeout(() => {
      var selectedData = JSON.parse(localStorage.getItem('selectedSubCategory'));
      if (selectedData != null && selectedData.length > 0) {
        this.openCategory(selectedData[0])
        localStorage.removeItem("selectedSubCategory");
      }
      this.solutionTypeSelect();
      // if(this.loggedInUserData){
      //   // this.submitSearch();
      //   if(this.category_text == 'Category')
      //   this.submitSearch();
      //   // this.onChangeSearch('',true, this.loggedInUserData.user_id);
      // }else{
      //   // if(this.category_text == 'Category')
      //     this.submitSearch();
      //   // this.onChangeSearch('',true);
      // }      
    }, 1000);

    // this.pageTitle.setTitle("Best Marketing Business Analytics Tools, Software Options | Intellisqr");
    /* this.meta.addTags([
      { name: 'description', content: 'Explore, Compare and Buy best Business Analytics solutions. Our analytics experts can help you understand the details and handhold you on your journey to choosing the best solution' },
      { name: 'keywords', content: 'Marketing Analytics Tools, Best Marketing Analytics Tools' }  
    ]); */

    this.setMetaData();
    //  this.metaService.createCanonicalURL();
  }

  onItemSelect(item: any) {
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  formValidation() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      message: ['', [Validators.required]]
    });
  }

  get g() { return this.contactUsForm.controls; }

  // open modal
  compareModalOpen() {

    $('#compareModal').modal('show')
    // $('#dummyModal').modal('show');
  }

  // to open favourite modal
  favouriteContent(type) {
    // this.isLike=1;
    if (this.isLike == 0) {
      this.isLike = 1
      document.getElementById("star2").classList.remove("hide-star")
    }
    else {
      this.isLike = 0;
      document.getElementById("star2").classList.add("hide-star")
    }

    if (this.loggedInUserData == undefined) {
      let element1: HTMLElement = document.getElementById('errorMessage_login') as HTMLElement;
      element1.click();
      // $('#favouriteStar').modal('show')
    } else {
      this.newFilter(this.subCategoryFilter, this.deploymentFilter, this.priceFilter, this.isLike)
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }
    else if (!this.contactUsForm.value.name.replace(/^\s+/g, '').length) {
      this.contactUsForm.value.name = ' ';
      return;
    }
    else if (!this.contactUsForm.value.message.replace(/^\s+/g, '').length) {
      this.contactUsForm.value.message = ' ';
      return;
    }
    else {

      var formData = new FormData();
      formData.append('contact_type', '1');
      formData.append('user_name', this.contactUsForm.value.name);
      formData.append('email', this.contactUsForm.value.email);
      formData.append('description', this.contactUsForm.value.message);
      this.showSpinner = true;
      this.service.postData(formData).subscribe((response: any) => {
        if (response.status == 1) {
          this.contactUsForm.reset();
          let element1: HTMLElement = document.getElementById('showThankscontactPopup') as HTMLElement;
          element1.click();
          // this.service.successAlert(response.message);
          this.showSpinner = false;

          this.submitted = false;
        } else {
          this.submitted = false;
          this.showSpinner = false;
          //  this.ErrorMessage = response.message;
          let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_contact') as HTMLElement;
          ErrorMEssageModal.click();
          // this.service.dangerAlert(response.message);
        }
      })
    }
  }

  globalSearch(e) {
    var formData = new FormData();
    formData.append('keyword', this.keyword);
    this.service.globalSearch(formData).subscribe((res: any) => {
      if (res.status == true) {
        if (this.currentList && this.currentList.solution_data) {
          this.currentList.solution_data = res.data.solutions
          this.currentList.category_data = res.data.categories
        } if (this.currentList && !this.currentList.solution_data) {
          this.currentList['solution_data'] = res.data.solutions;
          this.currentList['category_data'] = res.data.categories;
        }
        var searchStateData = {
          formdata: {
            "keywords": this.keywords,
            "category": this.category_id,
            "industry": this.industry_id,
            "page_no": this.page_no
          },
          searchListArray: {
            solution_data: res.data.solutions,
            category_data: res.data.categories
          }
        }
        localStorage.removeItem('search-list-info');


        if (this.currentList.solution_data.length > 0) {
          localStorage.setItem('search-list-info', <any>JSON.stringify(searchStateData));
          if (localStorage.getItem('search-list-info')) {
            this.currentListData = JSON.parse(localStorage.getItem('search-list-info'));
            this.noSolutionDataFound = false;
          }
          this.noSelectedDataFound = false;

          /* start New Array for showing category once for respective solution */
          var newArray = [];
          for (var i = 0; i < this.currentList.solution_data.length; i++) {
            var data = {
              id: this.currentList.solution_data[i].category,
              name: this.currentList.solution_data[i].title,
              detail_text: this.currentList.solution_data[i].about_us
            }
            if (newArray.length > 0) {
              for (var j = 0; j < newArray.length; j++) {
              }
            }
            newArray.push(data)
          }
          this.unique = [];
          this.unique = Array.from(newArray.reduce((a, o) => a.set(o.id, o), new Map()).values());
          for (var l = 0; l < this.unique.length; l++) {
            this.unique[l].solution_list = [];
            this.unique[l].solutiondata = [];
            this.unique[l].type = 'solution';
          }
          this.currentList.solution_data.forEach((obj) => {
            obj.liked = false;
            obj.type = 'solution';
            var existNotification = this.unique.find(({ id }) => obj.category === id);
            if (existNotification) {
              var pp = this.unique.find(x => x.id == obj.category)
              pp.solution_list.push(obj)
              pp.solutiondata = []
            }
          });
          /* End New Array for showing category once for respective solution */

        } else {
          this.noSelectedDataFound = true;
        }

      }
    }, err => {
      console.log(err);
    })
  }

  // Category List
  // categoryList() {
  //   this.service.getCategory().subscribe((response: any) => {
  //     if (response.status == 1) {
  //       this.category = response.data;
  //       this.category = this.category.filter(data => {
  //         return data.name != 'None' && data.name != 'All'
  //       })

  //       if (this.currentListData) {
  //         this.category.forEach(element => {
  //           if (element.id == this.currentListData.formdata.category) {
  //             // this.category_text = element.name;
  //             this.category_id = element.id;
  //           }
  //           if (element && element.is_trending == 1) {
  //             this.category_id = element.id
  //             this.category_text = element.name;
  //           }
  //         });
  //       }
  //     }
  //     let favSelected = localStorage.getItem('fav');
  //     this.spinner.hide();
  //     if (favSelected && favSelected == '0') {
  //       this.category_id = localStorage.getItem('catId');
  //       this.category_text = localStorage.getItem('catName');
  //       this.spinner.hide();
  //     } else {
  //       // this.submitSearch();
  //     }
  //   })
  // }

  // Industry List
  industryList() {
    this.service.getIndustry().subscribe((response: any) => {
      if (response.status == 1) {
        this.industry = response.data;

        if (this.currentListData) {
          this.industry.forEach(element => {
            this.currentListData.formdata.industry.forEach(item => {
              if (element.id == item) {
                element.isSelected = true;
                this.industryArray.push(element);
                this.industryFinalArray.push(element.id);
              }
              if (item == 1111) {
                element.isSelected = true;
              }
            })
          })
          this.industry_id = this.industryFinalArray;
        }
      }
    })
  }

  checkedIndustry(value) {
    if (value == true) {
      return true;
    }
  }
  // Create Industry Array
  onCheckboxChange(option, event) {
    if (event.target.checked) {
      if (option.name == "All") {
        this.industry.forEach(element => {
          element.isSelected = true;
        })
        this.allIsSelected = true;
        this.industryFinalArray = [];
        this.industryArray = [];
      }
      this.industryFinalArray.push(option.id);
      this.industryArray.push(option);
      var length = this.industry.length - 1;
      if (this.industryFinalArray.length == length) {
        this.industry.forEach(element => {
          element.isSelected = true;
          if (element.name == "All") {
            this.industryFinalArray = [];
            this.industryArray = [];
            this.industryFinalArray.push(element.id);
            this.industryArray.push(element);
          }
        })
      }
    } else {
      if (option.name == "All") {
        this.industry.forEach(element => {
          element.isSelected = false;
        })
      }
      this.allIsSelected = false;
      for (var i = 0; i < this.industry.length; i++) {
        if (this.industryFinalArray[i] == option.id) {
          this.industryArray.splice(i, 1);
          this.industryFinalArray.splice(i, 1);
          this.allIndustrySelected = false;
        }
      }
    }
    this.industry_id = this.industryFinalArray;
  }

  // Read More button click
  readMore(data) {

    if (!localStorage.getItem('userData')) {
      let url, str;
      url = `/solution-detail/${data.type}/${data.id}/${data.title}`;
      str = url.split(' ').join('');
      setTimeout(() => {
        this.router.navigateByUrl(str);
      }, 100);
      // let element: HTMLElement = document.getElementById('showloginPopup') as HTMLElement; 
      // element.click();
      // this.router.navigateByUrl('/solution-detail/' + data.id +'/'+ data.title)

    }
    else {
      let url, str;
      url = `/solution-detail/${data.type}/${data.id}/${data.title}`;
      str = url.split(' ').join('');
      setTimeout(() => {
        this.router.navigateByUrl(str);
      }, 100);
      // this.router.navigateByUrl('/solution-detail/' + data.id + '/' + data.title)
    }
  }

  readMoreObjective(data) {
    if (!localStorage.getItem('userData')) {
      let url, str;
      url = `/solution-detail/${data.type}/${data.id}/${data.title}`;
      str = url.split(' ').join('');
      setTimeout(() => {
        this.router.navigateByUrl(str);
      }, 100);
      // let element: HTMLElement = document.getElementById('showloginPopup') as HTMLElement; 
      // element.click();
      // this.router.navigateByUrl('/solution-detail/' + data.id +'/'+ data.title)

    }
    else {
      let url, str;
      url = `/solution-detail/${data.id}/${data.title}`;
      str = url.split(' ').join('');
      setTimeout(() => {
        this.router.navigateByUrl(str);
      }, 100);
      // this.router.navigateByUrl('/solution-detail/' + data.id + '/' + data.title)
    }
  }

  //Submit Search Form
  submitSearch(userId?) {
    this.newStringArr = [];
    this.itemList = [];
    this.itemList1 = [];
    this.itemList2 = [];
    this.BuyersGuide = [];
    this.subCategoryFilter = [];
    this.renderedBGuide = 0
    this.deploymentFilter = []
    this.priceFilter = []
    this.isFirst = false
    // this.auto.clear();
    // this.auto.close();
    if ((this.keywords == '' || this.keywords == undefined) && (this.category_id == '' || this.category_id == undefined) && (this.industry_id == undefined || this.industry_id.length == 0) && (userId == undefined)) {
      let element1: HTMLElement = document.getElementById('showvalidPopup') as HTMLElement;
      element1.click();
    } else {
      if (this.page_no == undefined) {
        this.page_no = 1;
      }
      if (this.keywords == undefined) {
        this.keywords = '';
      }
      if (this.category_id == undefined || this.category_id == '') {
        this.activeRoute.queryParams.subscribe(params => {
          this.category_id = params.cat_id;
        });
      }
      if (this.industry_id == undefined) {
        this.industry_id = [];
      }
      var formData = new FormData();
      formData.append('keywords', this.keywords);
      formData.append('subcategory', this.category_id);
      formData.append('industry', this.industry_id);
      formData.append('page_no', this.page_no);
      if (this.loggedInUserData) {
        formData.append('user_id', this.loggedInUserData.user_id);
      }
      this.service.searchData(formData).subscribe((response: any) => {
        if (response.status == 1) {
          this.currentList = response.data;
          // this.relatedBlog = response.data.blogs;
          this.relatedBlog = response && response.data && response.data.blogs ? response.data.blogs : {};
          localStorage.setItem('relatedBlog', JSON.stringify(this.relatedBlog))
          var searchStateData = {
            formdata: {
              "keywords": this.keywords,
              "subcategory": this.category_id,
              "industry": this.industry_id,
              "page_no": this.page_no
            },
            searchListArray: response.data
          }
          localStorage.removeItem('search-list-info');

          if (this.currentList.solution_data.length > 0) {
            localStorage.setItem('search-list-info', <any>JSON.stringify(searchStateData));
            // if (localStorage.getItem('search-list-info')) {
            //   this.currentListData = JSON.parse(localStorage.getItem('search-list-info'));
            //   this.noSolutionDataFound = false;
            // }
            // this.noSelectedDataFound = false;

            /* start New Array for showing category once for respective solution */
            var newArray = [];
            for (var i = 0; i < this.currentList.solution_data.length; i++) {
              var data = {
                id: this.currentList.solution_data[i].category,
                name: this.currentList.solution_data[i].category_name,
                detail_text: this.currentList.solution_data[i].category_description,
              }
              if (newArray.length > 0) {
                for (var j = 0; j < newArray.length; j++) {
                }
              }
              newArray.push(data)
            }
            this.unique = [];
            this.unique = Array.from(newArray.reduce((a, o) => a.set(o.id, o), new Map()).values());
            for (var l = 0; l < this.unique.length; l++) {
              this.unique[l].solution_list = [];
              this.unique[l].type = 'solution';
            }
            this.itemList = [];
            this.itemList1 = [];
            this.itemList2 = [];
            this.BuyersGuide = [];
            this.currentList.solution_data.forEach((obj) => {
              obj.liked = false;
              obj.type = 'solution';
              obj.img_logo = obj.logo ? `${environment.imageUrl}/uploads/solution_logo/${obj.logo}` : 'https://intellisqr.abcxyz.in/uploads/blogs/1626972131771142CDP_banner.jpg'
              // obj.selected = false;
              var existNotification = this.unique.find(({ id }) => obj.category === id);
              if (existNotification) {
                var pp = this.unique.find(x => x.id == obj.category)
                pp.solution_list.push(obj)
              }
              if (obj.subcategory_data && obj.subcategory_data.length) {
                obj.subcategory_data.forEach(element => {
                  if (this.renderedBGuide < obj.subcategory_data.length) {

                    this.BuyersGuide.push({ 'SubCategoryName': element.name, BuyerGuide: element.buyer_guide })
                    this.dropMore.push(true)

                    this.renderedBGuide++;
                  }

                  if (this.itemList.length == 0) {
                    this.itemList.push({ "id": element.id, "itemName": element.name, "buyerGuide": element.buyer_guide });
                    // if(element.buyer_guide)
                    // this.BuyersGuide.push({'SubCategoryName' : element.name, BuyerGuide : element.buyer_guide })
                  } else {
                    let isExist = this.itemList.filter(item => item.itemName == element.name).length > 0;
                    if (isExist) {

                    } else {
                      this.itemList.push({ "id": element.id, "itemName": element.name, "buyerGuide": element.buyer_guide });
                      // if(element.buyer_guide)
                      // this.BuyersGuide.push({'SubCategoryName' : element.name, BuyerGuide : element.buyer_guide })
                    }
                    // if(this.itemList.filter())
                  }
                });

              }


              if (obj.deployment) {
                if (this.itemList1.length == 0) {
                  this.itemList1.push({ "id": obj.deployment, "itemName": obj.deployment });
                } else {
                  let isExist2 = this.itemList1.filter(item => item.itemName == obj.deployment).length > 0;
                  if (isExist2) {

                  } else {
                    this.itemList1.push({ "id": obj.deployment, "itemName": obj.deployment });
                  }
                  // if(this.itemList.filter())
                }
              }


              if (obj.price) {
                this.itemList2[0] = { "id": "$", "itemName": "$" }
                this.itemList2[1] = { "id": "$$", "itemName": "$$" }
                this.itemList2[2] = { "id": "$$$", "itemName": "$$$" }
                this.itemList2[3] = { "id": "$$$$", "itemName": "$$$$" }
                this.itemList2[4] = { "id": "$$$$$", "itemName": "$$$$$" }
                if (this.itemList2.length == 0) {
                  // this.itemList2.push({"id": obj.price, "itemName": obj.price });
                } else {
                  let isExist3 = this.itemList2.filter(item => item.itemName == obj.price).length > 0;
                  if (isExist3) {

                  } else {
                    // this.itemList2.push({"id": obj.price, "itemName": obj.price });
                  }
                  // if(this.itemList.filter())
                }

                // this.itemList2.sort((a, b) => ( a["id"].length - b["id"].length))
                // this.itemList2.reverse()
              }
              this.itemList2.reverse()
            });
            /* End New Array for showing category once for respective solution */
          } else {
            this.noSelectedDataFound = true;
          }



        } 
        else {
          let popupModal: HTMLElement = document.getElementById('showErrorPopup') as HTMLElement;
          popupModal.click();
          this.noSelectedDataFound = true;
          if (this.currentList) {
            this.currentList.solution_data = [];
            this.currentList.white_paper_data = [];
          }
        }
      })
    }

    // var state = { category: this.category_text.split(' ').join('') };
    // var url = this.router.createUrlTree([], { relativeTo: this.activeRoute, queryParams: state }).toString();
    // this.router.navigateByUrl(url);
    this.spinner.hide();
  }

  goToSite(url) {
    if (url && url.site_url) {
      window.open(url.site_url, "_blank")

    }
    else {
      window.location.reload()
    }

  }

  goToWebsite(url) {
    if (url && url.website) {
      window.open(url.website, "_blank")

    }
    else {
      window.location.reload()
    }
  }

  selectItem(data, type, i) {
    this.comparepage = true;
    let check = type.target.checked
    this.compareSelect = data
    this.reRenderSolutionList(data, type, i);
    // this.compareSelection(data,type,i)
    if (check == true) {
      if (this.compareData.length >= 3) {
        alert('At a time maximum 3 item can select for compare');
        return;
      }
      this.compareData.push(data);
    }
    if (check == false) {
      this.compareData = this.compareData.filter((element) => element.solution_id !== data.solution_id);
      // setTimeout(() => {
      // this.compareData.splice(i, 1);
      // this.compareData = this.compareData;
      // },1000);
      // this.compareData.forEach(element => {
      // if(element.solution_id == data.solution_id)

      // });
    }
  }

  reRenderSolutionList(data, type, i) {
    this.unique.forEach(ele => {
      ele.solution_list.forEach(element => {
        if (element.solution_id == data.solution_id) {
          element.selected = type.target.checked
          if (this.compareData.length >= 3) {
            element.selected = !type.target.checked
          }
        }
      });
    });
    this.unique = this.unique;

  }

  crossDelete(i, item) {
    // this.compareData.pop(i);
    this.compareData.splice(i, 1);
    this.reRenderRemovedItem(i, item);
  }

  reRenderRemovedItem(i, data) {
    this.unique.forEach(ele => {
      ele.solution_list.forEach(element => {
        if (data.solution_id == element.solution_id) {
          element.selected = false;
        }
      });
    });
    this.unique = this.unique;
  }

  goToComparePage() {

    // this.compareItem=[]
    // this.compareData.forEach(element => {
    //   if(element.id){
    //   this.compareItem.push(element.id);
    // }

    if (this.compareData.length == 2) {
      this.cmp1 = this.compareData[0].id
      this.cmp2 = this.compareData[1].id

    }
    if (this.compareData.length == 3) {
      this.cmp1 = this.compareData[0].id
      this.cmp2 = this.compareData[1].id
      this.cmp3 = this.compareData[2].id

    }
    // });

    this.router.navigate(['/compare-solution'], { queryParams: { cmp1: this.cmp1, cmp2: this.cmp2, cmp3: this.cmp3 } })
  }


  // drag(){

  //   $("#compare").draggable({
  //     handle: ".modal-header"
  // });
  // }
  newStringArr: any = [];
  subCategorySelected(event?, item?, index?) {
    let data = this.itemList;
    data.forEach(element => {
      if (element.itemName == item.itemName && event.target.checked) {
        if (!this.isFirst) {
          this.BuyersGuide = []
          this.isFirst = true
        }
        this.subCategoryFilter.push(item.id);
        this.newStringArr.push(item.itemName);
        if (item.buyerGuide)
          this.BuyersGuide.push({ 'SubCategoryName': item.itemName, BuyerGuide: item.buyerGuide })
      }
      if (!event.target.checked) {
        this.subCategoryFilter = this.subCategoryFilter.filter(e => { return e != item.id })
        this.newStringArr = this.newStringArr.filter(e => { return e != item.itemName })
        this.BuyersGuide = this.BuyersGuide.filter(e => { return e.SubCategoryName != item.itemName })
      }
    });
    if (this.subCategoryFilter.length < 1) {
      this.isFirst = false
      data.forEach(element => {
        this.BuyersGuide.push({ 'SubCategoryName': element.itemName, BuyerGuide: element.buyerGuide })
      });
    }
    this.newFilter(this.subCategoryFilter, this.deploymentFilter, this.priceFilter, this.isLike)
    var state: any;
    if (this.newStringArr.length)
      state = { category: this.category_text ? this.category_text.split(' ').join('') : 'all', sub_category: this.newStringArr.join(',').split(' ').join('') };
    else
      state = { category: this.category_text ? this.category_text.split(' ').join('') : 'all' };
    var url = this.router.createUrlTree([], { relativeTo: this.activeRoute, queryParams: state }).toString();
    this.router.navigateByUrl(url);
  }

  deploymentSelected(event, item, index) {
    let data = this.itemList1;
    data.forEach(element => {
      if (element.itemName == item.itemName && event.target.checked) {
        this.deploymentFilter.push(item.id);
      }
      if (!event.target.checked) {
        this.deploymentFilter = this.deploymentFilter.filter(e => { return e != item.itemName })
      }
    });
    
    this.newFilter(this.subCategoryFilter, this.deploymentFilter, this.priceFilter, this.isLike)
    
  }

  priceSelected(event, item, index) {
    let data = this.itemList2;
    data.forEach(element => {
      if (element.itemName == item.itemName && event.target.checked) {
        this.priceFilter.push(item.id);
      }
      if (!event.target.checked) {
        this.priceFilter = this.priceFilter.filter(e => { return e != item.itemName })
      }
    });
    this.newFilter(this.subCategoryFilter, this.deploymentFilter, this.priceFilter, this.isLike)
  }


  newFilter(subcateogry?, deployment?, price?, fav?) {
    // this.auto.clear();
    // this.auto.close();
    // if((this.keywords == '' || this.keywords == undefined) && (this.category_id == '' || this.category_id == undefined) && (this.industry_id == undefined || this.industry_id.length == 0)){
    //   // let element1: HTMLElement = document.getElementById('showvalidPopup') as HTMLElement; 
    //   // element1.click();
    // }else{
    if (this.page_no == undefined) {
      this.page_no = 1;
    }
    if (this.keywords == undefined) {
      this.keywords = '';
    }
    if (this.category_id == undefined) {
      this.category_id = '';
    }
    if (this.industry_id == undefined) {
      this.industry_id = [];
    }
    var formData = new FormData();
    formData.append('keywords', this.keywords);
    //formData.append('category', this.category_id);
    formData.append('industry', this.industry_id);
    formData.append('page_no', this.page_no);
    if (deployment.length) {
      formData.append('deployment', this.deploymentFilter)
    }
    // if(subcateogry.lenght){
    //   formData.append('subcategory', this.subCategoryFilter)
    // }
    // if(subcateogry){
    //   this.activeRoute.queryParams.subscribe(params => {
    //     debugger;
    //     formData.append('subcategory', params.cat_id)
    //     // localStorage.setItem('cat_id', params.cat_id)
    //   });

    // }
    // var subCategoryFilter = localStorage.getItem('cat_id')
    formData.append('subcategory', localStorage.getItem('CAT_ID'))
    var subCat = localStorage.getItem('CAT_')

    // if (price.length) {
      formData.append('price', this.priceFilter)
    // }
    // if (fav == 1) {
      formData.append('is_like', this.isLike.toString())
    // }
    if(this.KeynewSearch == 'yes'){
      this.priceFilter = []
      this.isLike = 0
      formData.delete('price')
      formData.delete('is_like')
      document.getElementById("star2").classList.add("hide-star")
      formData.delete('deployment')
      this.deploymentFilter = []
    }   
    if(this.priceFilters == 'yes'){
      if(this.isLike == 1){
        formData.delete('is_like')
        formData.append('is_like', this.isLike.toString())
      }
      else{
        formData.delete('is_like')
      }
    }

    if(this.deploy == 'yes'){
      // debugger
      formData.delete('deployment')
      formData.append('deployment', this.deploymentFilter)
    }
    if (this.loggedInUserData) {
      formData.append('user_id', this.loggedInUserData.user_id);
    }
    this.service.searchData(formData).subscribe((response: any) => {
      if (response.status == 1) {
        this.currentList = response.data;
        this.KeynewSearch = ''
        this.priceFilters = ''
        this.deploy = ''
       
        // this.relatedBlog = response.data.blogs;
        this.relatedBlog = response && response.data && response.data.blogs ? response.data.blogs : {};
        localStorage.setItem('relatedBlog', JSON.stringify(this.relatedBlog))
        var searchStateData = {
          formdata: {
            "keywords": this.keywords,
            "category": this.category_id,
            "industry": this.industry_id,
            "page_no": this.page_no
          },
          searchListArray: response.data
        }
        localStorage.removeItem('search-list-info');

        if (this.currentList.solution_data.length > 0) {
          localStorage.setItem('search-list-info', <any>JSON.stringify(searchStateData));
          if (localStorage.getItem('search-list-info')) {
            this.currentListData = JSON.parse(localStorage.getItem('search-list-info'));
            this.noSolutionDataFound = false;
          }
          this.noSelectedDataFound = false;

          /* start New Array for showing category once for respective solution */
          var newArray = [];
          for (var i = 0; i < this.currentList.solution_data.length; i++) {
            var data = {
              id: this.currentList.solution_data[i].category,
              name: this.currentList.solution_data[i].category_name,
              detail_text: "",

            }
            if (newArray.length > 0) {
              for (var j = 0; j < newArray.length; j++) {
              }
            }
            newArray.push(data)
          }
          this.unique = [];
          this.unique = Array.from(newArray.reduce((a, o) => a.set(o.id, o), new Map()).values());
          for (var l = 0; l < this.unique.length; l++) {
            this.unique[l].solution_list = [];
            this.unique[l].type = 'solution';
          }
          // this.itemList = [];
          this.currentList.solution_data.forEach((obj) => {
            obj.liked = false;
            obj.type = 'solution';
            obj.img_logo = obj.logo ? `${environment.imageUrl}/uploads/solution_logo/${obj.logo}` : 'https://intellisqr.abcxyz.in/uploads/blogs/1626972131771142CDP_banner.jpg'
            // obj.selected = false;
            var existNotification = this.unique.find(({ id }) => obj.category === id);
            if (existNotification) {
              var pp = this.unique.find(x => x.id == obj.category)
              pp.solution_list.push(obj)
            }

            // if(obj.subcategory_data && obj.subcategory_data.length) {
            //   obj.subcategory_data.forEach(element => {
            //     if(this.itemList.length == 0){
            //       // this.itemList.push({"id": element.id, "itemName": element.name });
            //       if(element.buyer_guide)
            //       this.BuyersGuide.push({'SubCategoryName' : element.name, BuyerGuide : element.buyer_guide })
            //     }else{
            //       let isExist = this.itemList.filter(item=>item.itemName == element.name).length > 0;
            //       if(isExist) {

            //       }else{
            //         // this.itemList.push({"id": element.id, "itemName": element.name });
            //         if(element.buyer_guide)
            //         this.BuyersGuide.push({'SubCategoryName' : element.name, BuyerGuide : element.buyer_guide })
            //       }
            //       // if(this.itemList.filter())
            //     }
            //   });

            // }

            // if(obj.subcategory_id && obj.subcategory_name) {
            //   if(this.itemList.length == 0){
            //     this.itemList.push({"id": obj.subcategory_id, "itemName": obj.subcategory_name });
            //   }else{
            //     let isExist = this.itemList.filter(item=>item.itemName == obj.subcategory_name).length > 0;
            //     if(isExist) {

            //     }else{
            //       this.itemList.push({"id": obj.subcategory_id, "itemName": obj.subcategory_name });
            //     }
            //     // if(this.itemList.filter())
            //   }
            // }
          });
          /* End New Array for showing category once for respective solution */
        } else {
          this.noSelectedDataFound = true;
        }



      } 
      else {
        let popupModal: HTMLElement = document.getElementById('showErrorPopup') as HTMLElement;
        popupModal.click();
        this.noSelectedDataFound = true;
        if (this.currentList) {
          this.currentList.solution_data = [];
          this.currentList.white_paper_data = [];
        }
      }
    })
    // }
  }

  openLogin(solution) {
    let data = JSON.parse(localStorage.getItem('userData'))

    if (data && data.status == 'Active') {
      let solutionLike = solution.is_like == '1' ? '0' : '1';
      var formData = new FormData();
      formData.append('type_id', solution.id);
      formData.append('type', '1');
      formData.append('status', solutionLike);
      formData.append('user_id', data.user_id);
      this.service.likeDislike(formData).subscribe((res: any) => {
        if (this.category_text == 'Category') {
          this.onChangeSearch('', true, data.user_id);
        } else {
          this.submitSearch(data.user_id);
        }
        // this.
        // this.unique.forEach(ele => {
        //   if(ele && ele.solution_list){
        //     ele.solution_list.forEach(element => {
        //       if(element && element.solutiondata){
        //       element.solutiondata.forEach(e => {
        //         if(e.id == solution.id){
        //           e.liked = !e.liked;
        //         }
        //       });
        //       }
        //       else{
        //         if(element.id == solution.id){
        //           element.liked = !element.liked;
        //         }
        //       }
        //     });
        //   }
        //   // if(ele && ele.solutiondata){
        //   //   ele.solutiondata.forEach(el => {
        //   //     if(el.id == solution.id){
        //   //       el.liked = !el.liked;
        //   //     }
        //   //   });
        //   // }
        // });
        // this.unique = this.unique;
      }, err => {
        console.log(err);
      })
    } else {
      localStorage.setItem('redirectLogin', this.router.url);
      localStorage.setItem('fav', '0');
      localStorage.setItem('catId', this.category_id);
      localStorage.setItem('catName', this.category_text);
      this.router.navigateByUrl('account/signup');
    }
    //abhi
    this.solutionTypeSelect()
  }

  setMetaData(Id?) {
    
    if (Id) {
      var form = new FormData();
      // if (this.unique.length) { Id = this.unique[0].id }
      form.append("page_id", Id);
      form.append("page_name", "sub_categories");
      this.service.getMetaData(form).subscribe(res => {
        // this.meta.addTags([
        //   { name: 'title', content: "Best " + res.data.meta_title + " Software| <2022> Pricing, Features, Demo, Buyer Guide| Intellisqr" },
        //   { name: 'description', content: " Explore, understand and acquire the Best " + res.data.meta_title + "Software. Free consultation from experts to map according to your business needs, current ecosystem, data, analytics maturity and budget." },
        // ]);
        this.meta.addTags([
          { name: 'title', content: res.data.meta_title },
          { name: 'description', content: res.data.meta_desc },
        ]);
        let link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', res.data.canonical_tag);
        this.titleService.setTitle(res.data.meta_title);
      })
    }
    else {
      this.pageTitle.setTitle("Browse Curated Analytics Solutions");
      this.meta.addTags([
        { name: 'title', content: 'Browse Curated Analytics Solutions' },
        { name: 'description', content: 'Know the best data and analytics solutions for your requirements.   Our analytics experts can help you understand options and handhold you on the journey to choosing the best solution' },
      ]);
      this.metaService.createCanonicalURL();
    }
  }

  onSearch(event: string) {
    this.noSearchDataDiv = event;
    var form = new FormData();
    form.append("keyword", event);
    this.service.getSearchData(form).subscribe(res => {
      if (res.solution_data.length > 0) {
        for (let i = 0; i < res.solution_data.length; i++) {
          var subCatname = res.solution_data[i].subCatname.replace(/ /g, "")
          res.solution_data[i].link = location.origin + "/solution-detail/#/solution/" + res.solution_data[i].id + "/" + subCatname
        }
      }
      if (res.sub_category_data.length > 0) {
        for (let i = 0; i < res.sub_category_data.length; i++) {
          var subCatname = res.sub_category_data[i].subCatname.replace(/ /g, "")
          var Catname = "";
          // if (res.sub_category_data[i].Catname && res.sub_category_data[i].Catname != null)
          // Catname = res.sub_category_data[i].replace(/ /g, "")
          // var Catname = "MarketingAnalytics"
          res.sub_category_data[i].link = "/browse-solutions?cat_id=" + res.sub_category_data[i].id + "&catname=" + subCatname;
        }
      }
      this.KeynewSearch = 'yes'
      // document.getElementById("star2").classList.remove("hide-star")
      document.getElementById("star2").classList.add("hide-star")
      this.searchSolutionData = res.solution_data;
      this.searchCategoryData = res.sub_category_data;
    })
    /* setTimeout(function(){
      
    }, 1000); */
  }

  openCategory(item, data?, newSearch?: string) {
    this.newSearch = newSearch
    
    localStorage.setItem('CAT_ID', item.id)
    
    this.spinner.show();

    this.noSolutionDataFound = false; /////abhi
    var subCatname = item.subCatname.replace(/ /g, "")
    var link = "/browse-solutions?cat_id=" + item.id + "&catname=" + subCatname;
    this.searchSolutionData = [];
    this.searchCategoryData = [];
    this.keywords = this.noSearchDataDiv = "";
    // this.router.navigate(
    //   ['.'],
    //   { relativeTo: this.activeRoute, queryParams: { cat_id: item.id, catname: subCatname, type:"home-tab" } }
    // );

    this.router.navigateByUrl(link);
    // this.setSolutionData(item); ///abhi
    setTimeout(() => {
      const url = this.router.createUrlTree([], { relativeTo: this.activeRoute, queryParams: { cat_id: item.id, catname: subCatname, type: "home-tab" } }).toString()
      this.location.go(url);
      this.spinner.hide();
    }, 100);
    // let data = JSON.parse(localStorage.getItem('userData'))
    // this.submitSearch(data.user_id);
    if (data) {

      let ProfileElement: HTMLElement = document.getElementById('profile-tab') as HTMLElement;
      let ProfileTabElement: HTMLElement = document.getElementById('profile') as HTMLElement;
      /* let HomeElement: HTMLElement = document.getElementById('home-tab') as HTMLElement;
      let HomeTabElement: HTMLElement = document.getElementById('home') as HTMLElement; */
      ProfileElement.classList.remove('active');
      ProfileTabElement.classList.remove('show');
      ProfileTabElement.classList.remove('ative');
      /* HomeElement.classList.add('active');
      HomeTabElement.classList.add('show');
      HomeTabElement.classList.add('ative'); */
    }
  }

   setSolutionData(item) {
    var subCatData = [item.id.toString()]
    this.subCategoryFilter = item.id;
    // if (item.Catid) this.category_id = item.Catid;
    this.category_text = item.subCatname
    this.newFilter(subCatData, this.deploymentFilter, this.priceFilter, this.isLike)
    this.BuyersGuide = []
    this.BuyersGuide.push({ "BuyerGuide": item.buyerguide, "SubCategoryName": item.subCatname })
    setTimeout(() => {
      if (this.unique.length > 0) {
        this.unique[0].name = item.subCatname
        this.unique[0].id = item.id
      }
      this.filtersolutionData();
      this.setMetaData(item.id);
    }, 900);

    localStorage.setItem('fav', item.id)
    // this.submitSearch();
  }

  getTopCategories() {
    this.service.getAllSubCategories().subscribe(res => {
      if (res.sub_category_data.length > 0) {
        const slicedArray = res.sub_category_data.slice(0, 10);
        // for (let i = 0; i < slicedArray.length; i++) {
        //   var subCatname = slicedArray[i].subCatname.replace(/ /g, "")
        //   var Catname = "";
        //   // if (slicedArray[i].Catname && slicedArray[i].Catname != null)
        //     // Catname = slicedArray[i].Catname.replace(/ /g, "")
        //     res.sub_category_data[i].link = "/browse-solutions?cat_id=" + res.sub_category_data[i].id + "&catname=" + subCatname;
        // }
        this.topCategoryData = slicedArray;
      }
    });
  }

  solutionTab(type) {
    this.solutionTabChanges = true;
    this.activeRoute.queryParams.subscribe(params => {
      // { orderby: "price" }
      // if (!params.type) {
      //   var url = this.router.url + "&type=" + type
      //   this.router.navigateByUrl(url);
      // }
      // else {
      // const queryParams = { type: type };
      // this.router.navigate(
      //   [],
      //   {
      //     relativeTo: this.activeRoute,
      //     queryParams: queryParams,
      //     queryParamsHandling: 'merge', // remove to replace all query params by provided
      //   });
      const url = this.router.createUrlTree([], { relativeTo: this.activeRoute, queryParams: { cat_id: params.cat_id, catname: params.catname, type: type } }).toString()
      const link = "/browse-solutions?cat_id=" + params.cat_id + "&catname=" + params.catname + "&type=" + type;
      this.location.go(link);
      // this.location.go(url);
      // }
      // var url = this.activeRoute;

    });
  }

  solutionTypeSelect() {
    this.spinner.show();
    this.activeRoute.queryParams.subscribe(params => {
      if (params.cat_id) {
        this.noSolutionDataFound = false;
        var form = new FormData();
        form.append("subcatID", params.cat_id);
        this.service.getCategorybyId(form).subscribe(res => {
          this.currentList.solution_data = res.sub_category_data;
          let ProfileElement: HTMLElement = document.getElementById('profile-tab') as HTMLElement;
          let ProfileTabElement: HTMLElement = document.getElementById('profile') as HTMLElement;
          let HomeElement: HTMLElement = document.getElementById('home-tab') as HTMLElement;
          let HomeTabElement: HTMLElement = document.getElementById('home') as HTMLElement;
          setTimeout(() => {
            if (params.type == 'profile-tab') {
              // let buyerExpand: HTMLElement = document.getElementById('buyerExpand') as HTMLElement;
              // element.click();

              // HomeElement.classList.remove('active');
              // HomeTabElement.classList.remove('show');
              // HomeTabElement.classList.remove('active');

              ProfileElement.classList.add('active');
              ProfileTabElement.classList.add('show');
              ProfileTabElement.classList.add('active');
              // buyerExpand.click();
              if (this.unique.length > 0) { this.unique[0].name = res.sub_category_data.subCatname, this.unique[0].id = res.sub_category_data.id }
            }
            else {
              // ProfileElement.classList.remove('active');
              // ProfileTabElement.classList.remove('show');
              // ProfileTabElement.classList.remove('active');

              HomeElement.classList.add('active');
              HomeTabElement.classList.add('show');
              HomeTabElement.classList.add('active');
            }
           this.filtersolutionData();
            this.setSolutionData(res.sub_category_data)

          }, 700);
        });

      }
    })
    this.spinner.hide();
  }
  filtersolutionData() {
    this.itemList = [];
    this.itemList1 = [];
    this.itemList2 = [];
    if (this.currentList.solution_data != undefined && this.currentList.solution_data.length > 0) {
      this.currentList.solution_data.forEach((obj) => {
        if (obj.deployment) {
          if (this.itemList1.length == 0) {
            this.itemList1.push({ "id": obj.deployment, "itemName": obj.deployment });
          } else {
            let isExist2 = this.itemList1.filter(item => item.itemName == obj.deployment).length > 0;
            if (isExist2) {

            } else {
              this.itemList1.push({ "id": obj.deployment, "itemName": obj.deployment });
            }
            // if(this.itemList.filter())
          }
        }


        if (obj.price) {
          this.itemList2[0] = { "id": "$", "itemName": "$" }
          this.itemList2[1] = { "id": "$$", "itemName": "$$" }
          this.itemList2[2] = { "id": "$$$", "itemName": "$$$" }
          this.itemList2[3] = { "id": "$$$$", "itemName": "$$$$" }
          this.itemList2[4] = { "id": "$$$$$", "itemName": "$$$$$" }
          if (this.itemList2.length == 0) {
            // this.itemList2.push({"id": obj.price, "itemName": obj.price });
          } else {
            let isExist3 = this.itemList2.filter(item => item.itemName == obj.price).length > 0;
            if (isExist3) {

            } else {
              // this.itemList2.push({"id": obj.price, "itemName": obj.price });
            }
            // if(this.itemList.filter())
          }

          // this.itemList2.sort((a, b) => ( a["id"].length - b["id"].length))
          // this.itemList2.reverse()
        }
        this.itemList2.reverse()
      })
    }
  }

}
