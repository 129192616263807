import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { UserService } from '../../service/user.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public userLogged: any;
  public isSignUp: boolean = true;
  constructor(private router: Router, private service: UserService) {
    this.userLogged = JSON.parse(localStorage.getItem('userData'));
   }

  ngOnInit() {
    this.router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        // console.log(event.url);
        
        if(event.url == '/about-us' || event.url == 'list-solution' || event.url=='solution-detail'){
          this.isSignUp = false;
        }
        else{
          this.isSignUp = true;
        }
        // $(function(){
        //   alert('ok')
        //     $('iframe').attr('src', $('iframe').attr('src'));
        // });
      }})

     

  }

  logOut() {
    localStorage.removeItem('userData');
    localStorage.removeItem('search-list-info');
    localStorage.setItem('fav','1');
    window.location.reload();
    // this.router.navigateByUrl('/')
  }

  clearSolutionData() {
    if(localStorage.getItem('search-list-info')){
      localStorage.removeItem('search-list-info');
      localStorage.setItem('fav','1');
    }
  }

}
