import { Component } from '@angular/core';
import { Router,NavigationExtras, NavigationEnd } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Intllisqr - Home';
  public currentUrl: any;
  constructor(private router: Router, private activeRoute: ActivatedRoute, private titleService: Title) {
    this.router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        if(event.url == '/list-solution'){
          if(localStorage.getItem('userData')){
          }
          else{
            // this.router.navigateByUrl('/');
          }
        }

        

        if(this.currentUrl == '/'){
          this.titleService.setTitle('Intellisqr - Home');
        }
       else if(this.currentUrl == '/browse-solutions'){
          this.titleService.setTitle('Intellisqr - Browse solutions');
        }
        else if(this.currentUrl == '/list-solution'){
          this.titleService.setTitle('Intellisqr - List solution');
        }
        else if(this.currentUrl == '/contact-us'){
          this.titleService.setTitle('Intellisqr - Contact us');
        }
        else if(this.currentUrl == '/privacy-policy'){
          this.titleService.setTitle('Intellisqr - Privacy policy');
        }
        else if(this.currentUrl == '/terms-condition'){
          this.titleService.setTitle('Intellisqr - Terms & condition');
        }
        else if(this.currentUrl == '/account/login'){
          this.titleService.setTitle('Intellisqr - Login');
        }
        else if(this.currentUrl == '/account/forget-password'){
          this.titleService.setTitle('Intellisqr - Forget password');
        }
        // else if(this.currentUrl == '/account/'){
        //   this.titleService.setTitle('Intellisqr - New password');
        // }
        else if(this.currentUrl == '/account/signup'){
          this.titleService.setTitle('Intellisqr - Signup');
        }
        else if(this.currentUrl == '/faq'){
          this.titleService.setTitle('Intellisqr - FAQ');
        }
        else{
          var str = this.currentUrl;
          var res = str.substr(8, 12);
          if(res == '/newpassword'){
            this.titleService.setTitle('Intellisqr - New password');
          }
          else{
            this.titleService.setTitle('Intellisqr - Solution detail');
          }
        }
// BTKPR7049Q
      }})
   }

  onActivate(event) {
    window.scroll(0,0);
  }

}
