import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  public privacy_content: any;
  constructor(private service: UserService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.privacyPolicy();
  }

   // Privacy Policy content
   privacyPolicy() {
    this.service.getPrivacyPolicy().subscribe((response : any) =>{
      if(response.status == 1){
        this.privacy_content = response.data;
      }})
  }

}
