import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { timeInterval, timeout } from 'rxjs/operators';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { MetaService } from 'src/app/service/meta.service';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var $: any; 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  /*
  * Define all the global variable & there type
  */
  public consultUsForm : any;
  public submitted     : boolean = false;
  public showSpinner: boolean = false;
  public ErrorMessage: any;
  public category: any = [];
  public industry: any = [];
  public category_text: any = 'Category';
  public industry_text: any = 'Industry';
  public category_id: any = '';
  public industry_id: any = [];
  public page_no: any = 1;
  public searchForm: FormGroup;
  public callBackForm: FormGroup;
  public helpMeForm : FormGroup;
  public industryArray: any = [];
  public industryFinalArray  : any = [];
  public selectedIndustry    : any = {};
  public selectedSolution    : any;
  public showSignupbtn: boolean;
  public allIsSelected: boolean = false;
  public trendingSolution: any = [];
  public trendingBlogList : any = [];
  public bannerImg: any;
  public isSpinner: boolean = false;
  descriptionData: any='';
  doc_title: any='';
  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private meta: Meta,
    private pageTitle: Title,
    private titleService:Title,
    private spinner: NgxSpinnerService,
    private metaService: MetaService,
    @Inject(DOCUMENT) private dom
    ) { 
       
  }

  ngOnInit() {
    // this.spinner.show();
    /* this.metaService.createCanonicalURL(); */
    
    /*
    * Get localstorage & check value exist or not
    */

    if(localStorage.getItem('userData')){
      this.showSignupbtn = false;
    }
    else{
      this.showSignupbtn = true;
    }
    this.consultUsForm = this.formBuilder.group({
      name      : ['', [Validators.required]],
      email     : ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      message   : ['', [Validators.required]]
    });

    
    /*
    * Search Form formcontrols
    */
    this.searchForm = this.formBuilder.group({
      keywords: [''],
    });

    /*
    * Array list on initialization
    * @return All List Array
    */
    this.categoryList();
    this.industryList();
    this.blogList();
    this.getImg();
    this.trendingSolutionList();
    this.getDescriptionData();
    this.setMetaData();
    // this.banner();
    this.pageTitle.setTitle("Explore the best business analytics solutions | Intellisqr");  
    /* this.meta.addTags([
      { name: 'description', content: 'Intellisqr is the platform to explore and pick the best business analytics software suitable for your current needs, maturity, data availability, and pocket size.' },
      { name: 'keywords', content: 'Best Marketing analytics, Best Marketing Analytics Softwares, Marketing Analytics' },
    ]); */  
  }

  // api for banner image
  // banner(){
  //   this.service.getBanner().subscribe((res:any)=>{
  //     if(res.status==1){
  //       this.bannerImg=res.image_url
  //     }
  //   },(err)=>{
  //     console.log(err);
      
  //   })
  // }
  get g() { return this.consultUsForm.controls; }

  onSubmit(){
    this.submitted = true;
    if(this.consultUsForm.invalid) {
        return;
      } 
      else if(!this.consultUsForm.value.name.replace(/^\s+/g, '').length){
       this.consultUsForm.value.name = ' ';
       return;
     } 
     else if(!this.consultUsForm.value.message.replace(/^\s+/g, '').length){
       this.consultUsForm.value.message = ' ';
       return;
     }
      else {
        console.log(this.consultUsForm.value)
 
         var formData = new FormData();
      formData.append('contact_type','1');
      formData.append('user_name', this.consultUsForm.value.name);
      formData.append('email', this.consultUsForm.value.email);
      formData.append('description', this.consultUsForm.value.message);
      this.showSpinner = true;
      this.service.postData(formData).subscribe((response : any) =>{
     if(response.status == 1) {
       this.consultUsForm.reset();
       let element1: HTMLElement = document.getElementById('showThankscontactPopup') as HTMLElement; 
       element1.click();
       // this.service.successAlert(response.message);
       this.showSpinner = false;
 
       this.submitted = false;
     } else{
       this.submitted = false;
       this.showSpinner = false;
       this.ErrorMessage = response.message;
       let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_contact') as HTMLElement; 
       ErrorMEssageModal.click();
       // this.service.dangerAlert(response.message);
     }
      })
    }
    }
  getImg() {
    this.service.getBanner().subscribe(res=>{
      console.log(res);
      this.bannerImg = res.data
    })
  }

  setMetaData(){
    var form = new FormData();
    /* form.append("page_id", "33");
    form.append("page_name", "sub_categories"); */
    form.append("page_id", "4");
    form.append("page_name", "static_page");
    this.service.getMetaData(form).subscribe(res=>{
      this.meta.addTags([
        { name: 'title', content: res.data.meta_title },
        { name: 'description', content: res.data.meta_discription },
      ]); 
      let link: HTMLLinkElement = this.dom.createElement('link');
       link.setAttribute('rel', 'canonical');
       this.dom.head.appendChild(link);
       link.setAttribute('href', res.data.canonical_tag);
       this.doc_title = res.data.h1_tag; 
       this.titleService.setTitle(res.data.meta_title);

    })
  }
  

/*
* Category list
* @return Category List Array
*/
  categoryList() {
    this.service.getCategory().subscribe((response : any) =>{
      if(response.status == 1){
        this.category = response.data;
      }
    this.spinner.hide();
    },err=>{
      console.log(err);
      this.spinner.hide();
    })

  }

/*
* Industry list
* @return Industry List Array
*/
  industryList() {
    this.service.getIndustry().subscribe((response : any) =>{
      if(response.status == 1){
        this.industry = response.data;
      }})
  }

  blogList() {
    this.service.getTrendingBlog().subscribe((response : any) =>{
      if(response.status == 1){
          this.trendingBlogList = response.data;
          if(this.trendingBlogList.length > 3){
            this.trendingBlogList = this.trendingBlogList.slice(0,3);
          }
        // this.industry = response.data;
      }},err=>{
        console.log(err);
      })
  }

/*
* Trending solution list
* @return Trending Solution Array
*/
  trendingSolutionList() {
    this.service.getTrending().subscribe((response : any) =>{
      console.log(response);
      
      if(response.status == 1){
        this.trendingSolution = response.data;
        for(var i=0; i<3; i++){
          var image = '';
          if(i == 0){
            image = "assets/img/ic_trending_sol_1.png";
          }
          else if(i == 1){
            image = "assets/img/ic_trending_sol_2.png";
          }
          else{
            image = "assets/img/ic_trending_sol_3.png";
          }
          this.trendingSolution[i].image = image;
        }
      }})
  }


/*
* Select All industry list
* @param industry isSelected
* @return true
*/
  checkedIndustry(value){
    if(value == true){
      return true;
    }
  }

  getDescriptionData() {
    // this.isSpinner=true
    // this.spinner.show()
    this.service.getDescription().subscribe((res:any)=>{
      console.log(res);
      // setTimeout(() => {
        // this.spinner.hide()
        this.descriptionData = res.data.discription;
        // setTimeout(() => {
        //   this.isSpinner = false;
        //  }, 500);

      // }, 2000);
    },err=>{
      console.log(err);
    })
  }

/*
* Create Industry Array on checkbox check
* @param industry
* @param checked event
* @return Industry Array
*/
onCheckboxChange(option, event) {
  if(event.target.checked) {
    if(option.name == "All"){
      this.industry.forEach(element => {
        element.isSelected = true;
      })
      this.allIsSelected = true;
      this.industryFinalArray = [];
      this.industryArray = [];
    }
    this.industryFinalArray.push(option.id);
    this.industryArray.push(option);
    var length = this.industry.length - 1;
    if(this.industryFinalArray.length == length){
      this.industry.forEach(element => {
        element.isSelected = true;
        if(element.name == "All"){
          this.industryFinalArray = [];
          this.industryArray = [];
          this.industryFinalArray.push(element.id);
          this.industryArray.push(element);
        }
      })
    }
  } else {
    if(option.name == "All"){
      this.industry.forEach(element => {
        element.isSelected = false;
      })
    }
    this.allIsSelected = false;
  for(var i=0 ; i < this.industry.length; i++) {
    if(this.industryFinalArray[i] == option.id) {
      this.industryArray.splice(i,1);
      this.industryFinalArray.splice(i,1);
   }
 }
}
this.industry_id = this.industryFinalArray;
}

/*
* Read More button click
* @param solution
* @return route change to solution detail page
*/

readMore(data){
  console.log(data);
  let url,str; 
  url= `/solution-detail/solution/${data.id}/${data.title}`;
   str = url.split(' ').join('');
   setTimeout(() => {
    this.router.navigateByUrl(str);       
   }, 100);
  
  // this.router.navigateByUrl('/solution-detail/' + data.id)
}
  // readMore(data){
  // if(!localStorage.getItem('userData')){
  //   let element: HTMLElement = document.getElementById('showloginPopup') as HTMLElement; 
  //   element.click();
  // }
  // if(data){
  //   this.router.navigateByUrl('/solution-detail/' + data.id)
  // }
// }


  /*
  * Submit Search Form.
  * @return searchlist Array
  */
  submitSearch() {
    if((this.searchForm.value.keywords == '' || this.searchForm.value.keywords == undefined) && (this.category_id == '' || this.category_id == undefined) && (this.industry_id == undefined || this.industry_id.length == 0)){
      let element1: HTMLElement = document.getElementById('showvalidPopup') as HTMLElement; 
      element1.click();
    }else{
      var formData = new FormData();
      formData.append('keywords', this.searchForm.value.keywords);
      formData.append('category', this.category_id);
      formData.append('industry', this.industry_id);
      formData.append('page_no', this.page_no);
      this.service.searchData(formData).subscribe((response : any) =>{
        if(response.status == 1){
          var searchStateData = {
            formdata: {
              "keywords": this.searchForm.value.keywords,
              "category": this.category_id,
              "industry": this.industry_id,
              "page_no": this.page_no
            },
            searchListArray: response.data
          }
          this.router.navigate(['/browse-solutions'], { state: { searchListData:  searchStateData}});
        } else{
          let popupModal: HTMLElement = document.getElementById('showErrorPopup') as HTMLElement; 
          popupModal.click();
        }
      })
    }
  }

  /*
  * Solution Provider button click.
  * @return route change to list solution
  */
   soltuionProvider(){
    if(!localStorage.getItem('userData')){
      let element: HTMLElement = document.getElementById('showloginPopup') as HTMLElement; 
      element.click();
    }
    else{
      this.router.navigateByUrl('/list-solution')
    }
  }


  navigateToBlog(item){
    let url,str; 
    url= `/blog-details/${item.id}/${item.name}`;
     str = url.split(' ').join('');
     setTimeout(() => {
      this.router.navigateByUrl(str);       
     }, 100);
    // this.router.navigateByUrl('/blog-details/' + item.id)
  }


}
