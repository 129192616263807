import { NgModule, Injectable } from '@angular/core';

import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout/simple-layout.component';

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './onboard/login/login.component';
import { SignupComponent } from './onboard/signup/signup.component';
import { ForgetpasswordComponent } from './onboard/forgetpassword/forgetpassword.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BrowseSolutionsComponent } from './pages/browse-solutions/browse-solutions.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BiAndAnalyticsComponent } from './pages/bi-and-analytics/bi-and-analytics.component';
import { MlAndAiComponent } from './pages/ml-and-ai/ml-and-ai.component';
import { ListSolutionComponent } from './pages/list-solution/list-solution.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SolutionsDetailComponent } from './pages/solutions-detail/solutions-detail.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { NewpasswordComponent } from './onboard/newpassword/newpassword.component';

import {AuthGuard} from './guard/auth.guard';

import {RouterModule, Routes} from '@angular/router';
import { ConsultComponent } from './pages/consult/consult.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import { CompareSolutionComponent } from './pages/compare-solution/compare-solution.component';
import { DigitalmarketingComponent } from './digitalmarketing/digitalmarketing.component';
import { AllCategoriesComponent } from './pages/all-categories/all-categories.component';
import { BrowseSolutionsNewComponent } from './pages/browse-solutions-new/browse-solutions-new.component';
import { AdvisoryAndConsultingComponent } from './pages/advisory-and-consulting/advisory-and-consulting.component';
import { MarketingAnalyticsComponent } from './pages/marketing-analytics/marketing-analytics.component';
import { CustomerAnalyticsComponent } from './pages/customer-analytics/customer-analytics.component';


const routes: Routes = [
{
    path: 'account',
    component: LoginLayoutComponent,
    data: {
        title: 'account'
    },
    children: [
      { path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
      { path: 'signup', component: SignupComponent, canActivate: [AuthGuard]},
      // { path: 'forget-password', component: ForgetpasswordComponent, canActivate: [AuthGuard]},
      { path: 'newpassword/:auth_key', component: NewpasswordComponent, canActivate: [AuthGuard]}
    ]
},
{
    path: '',
    component: SimpleLayoutComponent,
    data: {
        title: 'landing-page'
    },
    children: [
      {path: '', component: HomeComponent },
      {path: 'consulting', component: DigitalmarketingComponent },
      {path: 'about-us', component: AboutUsComponent },
      {path: 'browse-solutions', component: BrowseSolutionsComponent },
      {path: 'contact-us', component: ContactUsComponent },
      {path: 'faq', component: FaqComponent },
      {path: 'list-solution', component: ListSolutionComponent },
      {path: 'privacy-policy', component: PrivacyPolicyComponent },
      // {path: 'solution-detail/:id/:name/:type', component: SolutionsDetailComponent },
      {path: 'solution-detail/:type/:id/:name', component: SolutionsDetailComponent },
      {path: 'terms-condition', component: TermConditionComponent },
      {path: 'consult', component:ConsultComponent},
      {path : 'blogs', component: BlogsComponent},
      {path : 'blog-details/:id/:name', component: BlogDetailsComponent},
      {path : 'compare-solution', component: CompareSolutionComponent},
      {path : 'all-categories', component: AllCategoriesComponent},
      {path: 'browse-solution', component: BrowseSolutionsNewComponent },
      {path:'bi-and-analytics',component:BiAndAnalyticsComponent},
      {path:'ml-and-ai',component:MlAndAiComponent},
      {path:'advisory-and-consulting',component:AdvisoryAndConsultingComponent},
      {path:'marketing-analytics',component:MarketingAnalyticsComponent},
      {path:'customer-analytics',component:CustomerAnalyticsComponent},







    ]
    
},
{ path: '**', pathMatch: 'full', component: PageNotFoundComponent }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes,{ useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
