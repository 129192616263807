import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public submitted = false;
  public ErrorMessage: any;
  public SuccessMessage: any;

  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
        username: ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
        password: ['', [Validators.required, Validators.minLength(6)]]
    });

  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if(this.loginForm.invalid) {
        return;
    }
    else{
      var formData = new FormData();
      formData.append('email', this.loginForm.value.username);
      formData.append('password', this.loginForm.value.password);
      this.service.loginUser(formData).subscribe((response : any) =>{
        if(response.status == 1){
         localStorage.setItem('userData', JSON.stringify(response.data));
          // this.service.successAlert(response.message);
          // this.router.navigateByUrl('/');
          this.loginForm.reset();
          this.submitted = false;
          this.SuccessMessage = response.message;
          let sucessMEssageModal: HTMLElement = document.getElementById('successMessage_login') as HTMLElement; 
          sucessMEssageModal.click();
        }
        else{
          if(response.data){
            if(response.data.verified == 0){
              this.service.sendNumber(response.data.user_id);
              document.getElementById("openModalButton").click();
            }else{
              this.ErrorMessage = response.message;
              let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_login') as HTMLElement; 
              ErrorMEssageModal.click();
            }
          }else{
            this.submitted = false;
            this.ErrorMessage = response.message;
            let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_login') as HTMLElement; 
            ErrorMEssageModal.click();
          }
        }
      })
         
    }
  }

  loginCheck() {
    if(localStorage && localStorage.getItem('redirectLogin')) {
      let url = localStorage.getItem('redirectLogin');
      this.router.navigateByUrl(url);
      localStorage.removeItem('redirectLogin');
    }else {
      this.router.navigateByUrl('/');
    }
  }

}
