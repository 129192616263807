import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router, NavigationExtras, NavigationEnd } from "@angular/router";
import { Meta, Title } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-list-solution',
  templateUrl: './list-solution.component.html',
  styleUrls: ['./list-solution.component.css']
})
export class ListSolutionComponent implements OnInit {

  public listSolutionForm: any;
  public submitted: boolean = false;
  public selectedFile: any;
  public upload_document: any = 'Upload Document';
  public ErrorMessage: any;
  public showSpinner: boolean = false;
  public uploadsucessMessage: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private router: Router,
    private meta: Meta,
    private pageTitle: Title,
    @Inject(DOCUMENT) private dom
  ) {
  }

  ngOnInit() {
    //initialize the form validations for callBackForm...
    this.listSolutionForm = this.formBuilder.group({
      cname: ['', [Validators.required]],
      cperson: ['', [Validators.required]],
      phone: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      overview: ['', [Validators.required]]
    });
    this.pageTitle.setTitle("List Data and Analytics Solution");
    this.meta.addTags([
      { name: 'title', content: "List Your Solution" },
      { name: 'description', content: 'Are you a vendor of business Analytics solution? Improve your visibility and Sell more by listing with Intellisqr' },
    ]);
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', 'https://www.intellisqr.com/#/list-solution');
  }

  get g() { return this.listSolutionForm.controls; }

  //onSubmit for submit details....
  onSubmit() {
    this.submitted = true;
    console.log(this.listSolutionForm.value.phone)
    if (this.listSolutionForm.invalid) {
      return;
    }
    else if (!this.listSolutionForm.value.cname.replace(/^\s+/g, '').length) {
      this.listSolutionForm.value.cname = ' ';
      return;
    }
    else if (!this.listSolutionForm.value.cperson.replace(/^\s+/g, '').length) {
      this.listSolutionForm.value.cperson = ' ';
      return;
    }
    else if (!this.listSolutionForm.value.overview.replace(/^\s+/g, '').length) {
      this.listSolutionForm.value.overview = ' ';
      return;
    }
    else {
      if (this.selectedFile == undefined) {
        this.selectedFile = '';
      }
      var formData = new FormData();
      formData.append('contact_type', '4');
      formData.append('company_name', this.listSolutionForm.value.cname);
      formData.append('user_name', this.listSolutionForm.value.cperson);
      formData.append('mobile', this.listSolutionForm.value.phone);
      formData.append('description', this.listSolutionForm.value.overview);
      formData.append('document', this.selectedFile);
      formData.append('email', this.listSolutionForm.value.email);
      console.log(formData)
      this.showSpinner = true;
      this.service.postData(formData).subscribe((response: any) => {
        if (response.status == 1) {
          this.selectedFile = '';
          this.upload_document = 'Upload Document';
          this.listSolutionForm.reset();
          // this.service.successAlert(response.message);
          this.submitted = false;
          this.showSpinner = false;
          this.uploadsucessMessage = response.message;
          let sucesspopupModal: HTMLElement = document.getElementById('showRequestSuccess') as HTMLElement;
          sucesspopupModal.click();
        } else {
          this.showSpinner = false;
          this.ErrorMessage = response.message;
          let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage') as HTMLElement;
          ErrorMEssageModal.click();
          // this.service.dangerAlert(response.message);
        }
      })
    }
  }

  uploadImage(event: any) {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      this.selectedFile = file;
      this.upload_document = this.selectedFile.name;
    }
  }
}
