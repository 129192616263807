import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { Meta, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MetaService } from 'src/app/service/meta.service';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  blogList: any = [];

  // blogList : any = [
  //   {
  //     id : 1,
  //     name : 'Why Data Storytelling is essential to strategic business cases',
  //     createdAt : "2021-05-10T20:11:00.143Z",
  //     type : 'Data Storytelling',
  //     img : 'https://i.ibb.co/Xsf2X0L/location.jpg',
  //     statusColor : 'green'
  //   },
  //   {
  //     id : 2,
  //     name : 'A neural network to help predict Retail Sales',
  //     createdAt : "2021-05-10T20:11:00.143Z",
  //     type : 'analytics',
  //     img : 'https://i.ibb.co/K2tN7nP/TVF-LOgo.jpg',
  //     statusColor : 'red'

  //   },
  //   {
  //     id : 3,
  //     name : 'Why the best SEO Strategy is Driven by UX',
  //     createdAt : "2021-05-10T20:11:00.143Z",
  //     type : 'Digital Marketing',
  //     img : 'https://i.ibb.co/rs6Pj9T/z52.jpg',
  //     statusColor : 'blue'

  //   },
  //   {
  //     id : 4,
  //     name : 'Why Data Storytelling is essential to strategic business cases',
  //     createdAt : "2021-05-10T20:11:00.143Z",
  //     type : 'Data Storytelling',
  //     img : 'https://i.ibb.co/BBZVXV7/netflick.jpg',
  //     statusColor : 'darkblue'

  //   },
  //   {
  //     id : 5,
  //     name : 'A neural network to help predict Retail Sales',
  //     createdAt : "2021-05-10T20:11:00.143Z",
  //     type : 'analytics',
  //     img : 'https://i.ibb.co/smCjH94/seoimage.jpg',
  //     statusColor : 'green'

  //   },
  //   {
  //     id : 6,
  //     name : 'Why the best SEO Strategy is Driven by UX',
  //     createdAt : "2021-05-10T20:11:00.143Z",
  //     type : 'Digital Marketing',
  //     img : 'https://i.ibb.co/JFCfprv/top-free-seo-tools-5f110b597b71e.png',
  //     statusColor : 'violet'

  //   }
  // ];
  constructor(private router: Router,
    private service: UserService,
    private meta: Meta,
    private pageTitle: Title,
    private spinner: NgxSpinnerService,
    private metaService: MetaService,
    private titleService:Title,
    @Inject(DOCUMENT) private dom
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.bloglist()
    this.pageTitle.setTitle("Best Business Analytics tools Blogs | Intellisqr");
    this.setMetaData();
    /* this.meta.addTags([
      { name: 'description', content: 'Know how Analytics/ AI/ML can be applied to solve your business challenges and gain insights in the areas of marketing optimization, social media analytics, Customer Churn, etc.' },
      { name: 'keywords', content: 'Best Business Analytics' }  
    ]); */
  }

  // api for fetching blog list
  bloglist() {
    this.service.getBlog().subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.blogList = res.data
        console.log(this.blogList);

      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  getColor(color) {
    return color;
  }

  navigateToBlog(item) {
    let url, str;
    url = `/blog-details/${item.id}/${item.name}`;
    str = url.split(' ').join('');
    setTimeout(() => {
      this.router.navigateByUrl(str);
    }, 100);
    // this.router.navigateByUrl('/blog-details/' + item.id + '/' + item.name)
  }
  setMetaData() {
    this.meta.addTags([
      { name: 'title', content: "Best Blogs to understand the analytics possibilities  | Intellisqr" },
      { name: 'description', content: "Know how Analytics/ AI/ML can be applied to solve your business challenges, gain insights and improve decision-making." },
    ]);
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', "https://www.intellisqr.com/blogs");
    this.titleService.setTitle("Best Blogs to understand the analytics possibilities  | Intellisqr");

  }

}
