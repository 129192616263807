import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { SimpleLayoutComponent } from './layout/simple-layout/simple-layout.component';
import { LoginHeaderComponent } from './component/login-header/login-header.component';
import { LoginComponent } from './onboard/login/login.component';
import { SignupComponent } from './onboard/signup/signup.component';
import { ForgetpasswordComponent } from './onboard/forgetpassword/forgetpassword.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BrowseSolutionsComponent } from './pages/browse-solutions/browse-solutions.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BiAndAnalyticsComponent } from './pages/bi-and-analytics/bi-and-analytics.component';
import { ListSolutionComponent } from './pages/list-solution/list-solution.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SolutionsDetailComponent } from './pages/solutions-detail/solutions-detail.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { ReactiveFormsModule }    from '@angular/forms';
import {UserService} from "./service/user.service";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { AuthGuard } from './guard/auth.guard';
import { NewpasswordComponent } from './onboard/newpassword/newpassword.component';
import { FormsModule } from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SafeHtml } from './shared/fullhtml.pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { ConsultComponent } from './pages/consult/consult.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { DomsanitizePipe } from './_helpers/domsanitize.pipe';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CompareSolutionComponent } from './pages/compare-solution/compare-solution.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import { MetaService } from './service/meta.service';
import { DigitalmarketingComponent } from './digitalmarketing/digitalmarketing.component';
import { AllCategoriesComponent } from './pages/all-categories/all-categories.component';
import { BrowseSolutionsNewComponent } from './pages/browse-solutions-new/browse-solutions-new.component';
import { MlAndAiComponent } from './pages/ml-and-ai/ml-and-ai.component';
import { CustomerAnalyticsComponent } from './pages/customer-analytics/customer-analytics.component';
import { MarketingAnalyticsComponent } from './pages/marketing-analytics/marketing-analytics.component';
import { AdvisoryAndConsultingComponent } from './pages/advisory-and-consulting/advisory-and-consulting.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginLayoutComponent,
    SimpleLayoutComponent,
    LoginHeaderComponent,
    LoginComponent,
    SignupComponent,
    ForgetpasswordComponent,
    AboutUsComponent,
    MlAndAiComponent,
    BiAndAnalyticsComponent,
    CustomerAnalyticsComponent,
    MarketingAnalyticsComponent,
    AdvisoryAndConsultingComponent,
    BrowseSolutionsComponent,
    ContactUsComponent,
    FaqComponent,
    ListSolutionComponent,
    PrivacyPolicyComponent,
    SolutionsDetailComponent,
    TermConditionComponent,
    NewpasswordComponent,
    SafeHtml,
    ConsultComponent,
    BlogsComponent,
    BlogDetailsComponent,
    DomsanitizePipe,
    CompareSolutionComponent,
    PageNotFoundComponent,
    DigitalmarketingComponent,
    AllCategoriesComponent,
    BrowseSolutionsNewComponent    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, 
    FormsModule,
    OwlModule,
    TruncateModule,
    AutocompleteLibModule,
    ToastrModule.forRoot(),
    AngularMultiSelectModule,
    NgxSpinnerModule
  ],
  providers: [UserService, AuthGuard, MetaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
