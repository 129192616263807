import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { Subscription } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css']
})
export class LoginHeaderComponent implements OnInit {
  public otpForm: FormGroup;
  public register_userId: any;
  public showotpSpinner: boolean = false;
  public subscription: Subscription;
  public user_id: any = {};
  public SuccessMessageOtp: any;
  public ErrorMessage: any;

  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router) { 
    this.otpForm = this.formBuilder.group({
      number1: ['', Validators.required],
      number2: ['', Validators.required],
      number3: ['', Validators.required],
      number4: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.subscription = this.service.getNumber().subscribe(number => { this.user_id = number });
  }

  otpkeyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  otpKeyNumber1(event: any){
    if(this.otpForm.value.number1.length == 1){
      event.preventDefault();
    }
  }
  otpKeyNumber2(event: any){
    if(this.otpForm.value.number2.length == 1){
      event.preventDefault();
    }
  }
  otpKeyNumber3(event: any){
    if(this.otpForm.value.number3.length == 1){
      event.preventDefault();
    }
  }
  otpKeyNumber4(event: any){
    if(this.otpForm.value.number4.length == 1){
      event.preventDefault();
    }
  }

  otpSubmit() {
    var OTP = this.otpForm.value.number1 + '' + this.otpForm.value.number2 + '' + this.otpForm.value.number3 + '' + this.otpForm.value.number4 ;
    var formData = new FormData();
    formData.append('otp', OTP);
    formData.append('user_id', this.user_id.text);
    this.showotpSpinner = true;
    this.service.otpVerify(formData).subscribe((response : any) =>{
      if(response.status == 1){
        // this.service.successAlert(response.message);
        this.otpForm.reset();
        this.showotpSpinner = false;
        $('#exampleModal').hide();
        $('.modal-backdrop').hide();
        $('body').removeClass('modal-open');
        $('body').css('padding-right','0');
        // this.router.navigateByUrl('/account/login')
        this.SuccessMessageOtp = response.message;
        let sucessMEssageModal: HTMLElement = document.getElementById('successMessage_otp') as HTMLElement; 
        sucessMEssageModal.click();
      }
      else{
        // this.service.dangerAlert(response.message);
        this.otpForm.reset();
        this.showotpSpinner = false;
        this.ErrorMessage = response.message;
        let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_otp') as HTMLElement; 
        ErrorMEssageModal.click();
      }
    })
  }

  resendOtp() {
    this.otpForm.reset();
    document.getElementById('exampleInputEmail11').focus();
    var formData = new FormData();
    formData.append('user_id', this.user_id.text);
    this.service.otpResend(formData).subscribe((response : any) =>{
      if(response.status == 1){
        // this.service.successAlert(response.message);
        this.SuccessMessageOtp = response.message;
        let sucessMEssageModal: HTMLElement = document.getElementById('successMessage_otp') as HTMLElement; 
        sucessMEssageModal.click();
      }
      else{
        // this.service.dangerAlert(response.message);
        this.ErrorMessage = response.message;
        let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_otp') as HTMLElement; 
        ErrorMEssageModal.click();
      }
    })
  }

  called(m, event){
    if(event.keyCode != 8){
      if(m == 'exampleInputEmail11'){
        document.getElementById('exampleInputEmail12').focus();
        } else if(m == 'exampleInputEmail12'){
        document.getElementById('exampleInputEmail13').focus();
        }
        else if(m == 'exampleInputEmail13'){
          document.getElementById('exampleInputEmail14').focus();
        }
    }
    }

    loginCheck() {
      if(localStorage && localStorage.getItem('redirectLogin')) {
        let url = localStorage.getItem('redirectLogin');
        this.router.navigateByUrl(url);
        localStorage.removeItem('redirectLogin');
      }else {
        this.router.navigateByUrl('/');
      }
    }

}
