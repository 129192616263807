import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

declare var $: any; 

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  public questionArray: any = [];
  public question1: any;
  public question2: any;
  public question3: any;
  public question4: any;
  public question5: any;
  public first_question_tab: boolean = true;
  public second_question_tab: boolean = false;
  public third_question_tab: boolean = false;
  public fourth_question_tab: boolean = false;
  public fifth_question_tab: boolean = false;

  public first_answer_key: any;
  public second_answer_key: any;
  public third_answer_key: any;
  public fourth_answer_key: any;
  public fifth_answer_key: any;

  public finalAnswer: any = [];
  public finalQuestion: any = [];
  public finalMessage: any;

  public activeFirstTab: boolean;
  public activeSecondTab: boolean;
  public activeThirdTab: boolean;
  public activeFourthTab: boolean;
  public activeFifthTab: boolean;

  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    // this.initializeAllFormElement();
    // this.questionList();
  }

  // Question List
  questionList() {
    this.service.getFAQquestions().subscribe((response : any) =>{
      if(response.status == 1){
        this.questionArray = response.data;
      }})
  }

  initializeAllFormElement(){
    //here we will display the model of verification.......
    var question_first: HTMLInputElement =<HTMLInputElement>document.getElementById('question1'); 
    this.question1 = question_first;
    this.question1.style.display = 'block';

    // initialize question tab
    var question_second: HTMLInputElement =<HTMLInputElement>document.getElementById('question2'); 
    this.question2 = question_second;

    // initialize question tab
    var question_third: HTMLInputElement =<HTMLInputElement>document.getElementById('question3'); 
    this.question3 = question_third;

    // initialize question tab
    var question_fourth: HTMLInputElement =<HTMLInputElement>document.getElementById('question4'); 
    this.question4 = question_fourth;

    // initialize question tab
    var question_fifth: HTMLInputElement =<HTMLInputElement>document.getElementById('question5'); 
    this.question5 = question_fifth;

    // Set by default hide question tab
    this.question2.style.display = 'none';
    this.question3.style.display = 'none';
    this.question4.style.display = 'none';
    this.question5.style.display = 'none';

  }

   // Next question......  
   jumpToNextForm(key : any){
    switch(key) {
      case 'question1':
        this.question1.style.display = 'none';
        this.question2.style.display = 'block';
        this.first_question_tab = false;
        this.second_question_tab = true;
        break;
      case 'question2':
        this.question2.style.display = 'none';
        this.question3.style.display = 'block';
        this.second_question_tab = false;
        this.third_question_tab = true;
        break;
      case 'question3':
        this.question3.style.display = 'none';
        this.question4.style.display = 'block';
        this.third_question_tab = false;
        this.fourth_question_tab = true;
        break;
      case 'question4':
        this.question4.style.display = 'none';
        this.question5.style.display = 'block';
        this.fourth_question_tab = false;
        this.fifth_question_tab = true;
        break;
    }
  }	

  // BackToPrevious Question
  backToPreviousForm(key : any){
    switch(key) {
      case 'question2':
        this.question1.style.display = 'block';
        this.question2.style.display = 'none';
        this.first_question_tab = true;
        this.second_question_tab = false;
        this.activeSecondTab = false;

        break;
      case 'question3':
        this.question2.style.display = 'block';
        this.question3.style.display = 'none';
        this.second_question_tab = true;
        this.third_question_tab = false;
        this.activeThirdTab = false;

        break;
      case 'question4':
        this.question3.style.display = 'block';
        this.question4.style.display = 'none';
        this.third_question_tab = true;
        this.fourth_question_tab = false;
        this.activeFourthTab = false;

        break;
      case 'question5':
        this.question4.style.display = 'block';
        this.question5.style.display = 'none';
        this.fourth_question_tab = true;
        this.fifth_question_tab = false;
        this.activeFifthTab = false;

        break;
      }
  }

  // First Question submit
  firstQuestionsubmit(question_id){
    this.finalAnswer[0] = this.first_answer_key;
    this.finalQuestion[0] = question_id;
    this.activeFirstTab = true;
  }

  // Second Question submit
  secondQuestionsubmit(question_id){
    this.finalAnswer[1] = this.second_answer_key;
    this.finalQuestion[1] = question_id;
    this.activeSecondTab = true;
  }

  // Third Question submit
  thirdQuestionsubmit(question_id){
    this.finalAnswer[2] = this.third_answer_key;
    this.finalQuestion[2] = question_id;
    this.activeThirdTab = true;
  }

  // Fourth Question submit
  fourthQuestionsubmit(question_id){
    this.finalAnswer[3] = this.fourth_answer_key;
    this.finalQuestion[3] = question_id;
    this.activeFourthTab = true;
  }

  // Fifth Question submit
  fifthQuestionsubmit(question_id){
    this.finalAnswer[4] = this.fifth_answer_key;
    this.finalQuestion[4] = question_id;
    this.activeFifthTab = true;
  }

  // submit Answer
  submitAnswer() {
    if(!localStorage.getItem('userData')){
      let element: HTMLElement = document.getElementById('showloginPopup') as HTMLElement; 
      element.click();
    }
    else{
    var formData = new FormData();
    formData.append('question_id', this.finalQuestion);
    formData.append('answer_option', this.finalAnswer);
    this.service.sendFaqData(formData).subscribe((response : any) =>{
      if(response.status == 1) {
        //used for dismiss current model open and another modal.......    
        this.finalMessage = response.message;
        let element1: HTMLElement = document.getElementById('showthaksPopup') as HTMLElement; 
        element1.click();
        // this.service.successAlert(response.message);
        // this.service.resetCurrentRouter();
      } else{
        this.service.dangerAlert(response.message);
      }
    })
  }
  }

  closeThankyou(){
    document.getElementById('thakyouModal').style.display = 'none';
    $('body').removeClass("modal-open");
    $('body div').removeClass("modal-backdrop");
    this.service.resetCurrentRouter();
    this.router.navigateByUrl('/');
  }

}
