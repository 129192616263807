import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router, NavigationExtras, NavigationEnd } from "@angular/router";
import { BrowseSolutionsComponent } from '../browse-solutions/browse-solutions.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Meta, Title } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MetaService } from 'src/app/service/meta.service';

@Component({
  providers: [BrowseSolutionsComponent],
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css']
})
export class AllCategoriesComponent implements OnInit {

  constructor(private service: UserService, private router: Router, private browseComponent: BrowseSolutionsComponent, private spinner: NgxSpinnerService, private meta: Meta, private pageTitle: Title, @Inject(DOCUMENT) private dom,private metaService: MetaService) { }
  AllCategoriesData = [];

  ngOnInit() {
    this.spinner.show();
    localStorage.removeItem("selectedSubCategory");
    this.service.getAllSubCategories().subscribe(res => {
      /* console.log("data", res) */
      // if (res.sub_category_data.length > 0) {
      //   for (let i = 0; i < res.sub_category_data.length; i++) {
      //     var subCatname = res.sub_category_data[i].subCatname.replace(/ /g, "")
      //     var Catname = "";
      //     if (res.sub_category_data[i].Catname && res.sub_category_data[i].Catname != null)
      //       Catname = res.sub_category_data[i].Catname.replace(/ /g, "")
      //     res.sub_category_data[i].link = "/browse-solutions?category=" + Catname + "&sub_category=" + subCatname;
      //   }
      // }
      // console.log("data", res)
      // var data = res.sub_category_data.sort((a, b) => a.subCatname > b.subCatname ? 1 : -1)
      // console.log("sorted2", data)

      this.AllCategoriesData = res.sub_category_data;
      this.spinner.hide();
    })
    this.pageTitle.setTitle("Data and Analytics Solution Categories");
    this.meta.addTags([
      { name: 'title', content: "Data and Analytics Solution Categories" },
      { name: 'description', content: 'Browse Data and Analytics Solutions Required to Solve your Business Challenges' }
    ]);
    // let link: HTMLLinkElement = this.dom.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // this.dom.head.appendChild(link);
    // link.setAttribute('href', '- https://www.intellisqr.sapphiresolutions.in.net/#/all-categories');
    this.metaService.createCanonicalURL();
  }
  public openCategory(item): void {
    var subCatname = item.subCatname.replace(/ /g, "")
    var link = "/browse-solutions?cat_id=" + item.id + "&catname=" + subCatname;
    /* console.log("asdas", item) */
    // var link = "/browse-solutions?category=" + Catname + "&sub_category=" + subCatname;  
    this.router.navigateByUrl(link)
    var data = []
    data.push(item)
    localStorage.setItem('selectedSubCategory', <any>JSON.stringify(data));


    // setTimeout(() => {

    //   this.router.navigateByUrl(item.link);
    // }, 2000);
    /* let data = JSON.parse(localStorage.getItem('userData'))
    this.submitSearch(data.user_id); */
  }

}
