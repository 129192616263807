import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras, NavigationEnd, NavigationStart } from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import {OwlCarousel} from 'ngx-owl-carousel';
import {DomSanitizer, Meta, SafeResourceUrl, Title} from '@angular/platform-browser';
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { MetaService } from 'src/app/service/meta.service';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT, } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-solutions-detail',
  templateUrl: './solutions-detail.component.html',
  styleUrls: ['./solutions-detail.component.css']
})
export class SolutionsDetailComponent implements OnInit {
  public solutionData: any = {};
  public solution_id: any;
  morecasestudy = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 100,
    navText: ['', ''],
    navigation: false,
    responsive: {
    0: {
    items: 1,
    dots: false,
    navigation: false,
    navText: ['', '']
    },
    400: {
    items: 1,
    dots: false,
    navigation: false
    },
    740: {
    items: 2,
    dots: false,
    navigation: false
    },
    940: {
    items: 4
    }
    },
    nav: true
  };
  public projects: any = [];
  public videoURL: any;
  public is_youtube_url: any;
  public demo_youtube_url: any;
  public isSpinner: boolean = false;
  safeSrc: SafeResourceUrl;
  safeUrl: SafeResourceUrl;
  public sessionMessage: any;
  readMoreType: any;
  imageData : any = [];
  solutionTitle;
  constructor(private service: UserService, private router: Router, private activeRoute: ActivatedRoute,private sanitizer: DomSanitizer, private _location: Location, private spinner: NgxSpinnerService,private meta: Meta,private metaService: MetaService,private titleService:Title,@Inject(DOCUMENT) private dom) {
    // if(localStorage.getItem('userData')){
    // }
    // else{
    //   // this.router.navigateByUrl('/');
    // }
    
    this.solution_id = this.activeRoute.snapshot.paramMap.get("id");
    this.readMoreType = this.activeRoute.snapshot.paramMap.get('type');
  }

  ngOnInit() {
    // this.spinner.show();
    this.isSpinner = true;
    /* this.metaService.createCanonicalURL(); */
    if(this.readMoreType == 'solution'){
      this.solutionDetail(this.readMoreType);
    }if(this.readMoreType == 'objective') {
      this.solutionDetail(this.readMoreType);
    }
  }

  // Solution Detail
  solutionDetail(type) {
    if(type == 'solution') {
      var formData = new FormData();
      formData.append('solution_id', this.solution_id);

      this.service.getSolution(formData).subscribe((response : any) =>{
        if(response.status == 1){
          this.solutionData = response.data;
          this.projects = this.solutionData.all_logo_data;
          if(this.projects.length > 3){
            this.morecasestudy.loop = true;
            this.morecasestudy.touchDrag = true;
            this.morecasestudy.mouseDrag = true;
          }
          this.imageData = this.solutionData && this.solutionData.white_papers_data ? this.solutionData.white_papers_data : [];
          // this.imageData=[{file_name:null},{file_name:null}]
          this.is_youtube_url = this.solutionData.solution_data.is_youtube_url;
          this.solutionTitle = this.solutionData.solution_data.title;
          this.safeSrc = this.transform(this.solutionData.solution_data.youtube_url+'?autoplay=0');
          if(this.solutionData.all_video_data.length != 0){
            this.demo_youtube_url = this.solutionData.all_video_data[0].is_youtube_url;
            this.safeUrl = this.transform(this.solutionData.all_video_data[0].video);
          }
          setTimeout(() => {
           this.isSpinner = false;
           this.setMetaData();
          }, 500);
      }
      else{
        // this._location.back();
        if(response.error_code == 461){
          this.isSpinner = false;
          document.getElementById("session_message_btn").click();
        }
        
      }
      })
    }
    if(type == 'objective') {
      var formData = new FormData();
      formData.append('id', this.solution_id);
      this.service.getObjective(formData).subscribe((response : any) =>{
        if(response.status == 1){
          this.solutionData = response && response.data && response.data.length ? response.data[0] : response.data;
          this.imageData = this.solutionData && this.solutionData.white_papers_data ? this.solutionData.white_papers_data : [];
          this.solutionTitle = this.solutionData.title;
          // this.projects = this.solutionData.all_logo_data;
          // if(this.projects.length > 3){
          //   this.morecasestudy.loop = true;
          //   this.morecasestudy.touchDrag = true;
          //   this.morecasestudy.mouseDrag = true;
          // }
          // this.is_youtube_url = this.solutionData.is_youtube_url;
          // this.safeSrc = this.transform(this.solutionData.youtube_url+'?autoplay=0');
          // if(this.solutionData.all_video_data.length != 0){
          //   this.demo_youtube_url = this.solutionData.all_video_data[0].is_youtube_url;
          //   this.safeUrl = this.transform(this.solutionData.all_video_data[0].video);
          // }
          setTimeout(() => {
           this.isSpinner = false;
           this.setMetaData();
          }, 500);
      }
      else{
        // this._location.back();
        if(response.error_code == 461){
          this.isSpinner = false;
          document.getElementById("session_message_btn").click();
        }
        
      }
      })
    }

  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  logOutsession() {
    localStorage.removeItem('userData');
    localStorage.removeItem('search-list-info');
    this.router.navigateByUrl('/account/login')
  }
  setMetaData(){
    var form = new FormData();
    form.append("page_id", this.solution_id);
    form.append("page_name", "solutions");
    this.service.getMetaData(form).subscribe(res=>{
      this.meta.addTags([
        { name: 'title', content: res.data.meta_title },
        { name: 'description', content: res.data.meta_desc},
        
      ]); 
      let link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', res.data.canonical_tag);
      this.titleService.setTitle(res.data.meta_title);
    })
    // console.log("asdasa",this.solutionData)
    // this.meta.addTags([
    //   { name: 'title', content: this.solutionTitle+ " | <2022> Pricing, Features, Demo, Buyer Guide| Intellisqr"},
    //   { name: 'description', content: " Looking to acquire "+this.solutionTitle +" Know more about features, pricing, demos. Avail free consultation from experts to assess the fitment for your business needs, current ecosystem, data, analytics maturity and budget."},
    // ]); 
    // let link: HTMLLinkElement = this.dom.createElement('link');
    //  link.setAttribute('rel', 'canonical');
    //  this.dom.head.appendChild(link);
    //  link.setAttribute('href', "https://www.intellisqr.com/solution-detail/solution/"+this.solution_id+"/"+this.solutionTitle);
  }

  backToResult(){
    this._location.back();
  }


}
