import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { MustMatch } from '../../_helpers/must-match.validator';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-newpassword',
  templateUrl: './newpassword.component.html',
  styleUrls: ['./newpassword.component.css']
})
export class NewpasswordComponent implements OnInit {
  public newPasswordForm: FormGroup;
  public submitted = false;
  public showSpinner: boolean = false;
  public authKey: any;
  public SuccessMessage: any;
  public ErrorMessage: any;

  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router, private activeRoute: ActivatedRoute) { 
    this.authKey = this.activeRoute.snapshot.params.auth_key;
  }

  ngOnInit() {
    this.newPasswordForm = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmpassword: ['', Validators.required]}, 
        {
          validator: MustMatch('password', 'confirmpassword')
      });
  }

  get f() { return this.newPasswordForm.controls; }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newPasswordForm.invalid) {
        return;
    }
    else{
      var formData = new FormData();
      formData.append('key', this.authKey);
      formData.append('new_password', this.newPasswordForm.value.password);
      formData.append('cnf_new_password', this.newPasswordForm.value.confirmpassword);
      this.showSpinner = true;
      this.service.newPassword(formData).subscribe((response : any) =>{
        if(response.status == 1){
          this.showSpinner = false;
          this.newPasswordForm.reset();
          this.submitted = false;
          this.SuccessMessage = response.message;
          let sucessMEssageModal: HTMLElement = document.getElementById('successMessage_newpassword') as HTMLElement; 
          sucessMEssageModal.click();
        }
        else{
          this.submitted = false;
          this.showSpinner = false;
          this.ErrorMessage = response.message;
          let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_newpassword') as HTMLElement; 
          ErrorMEssageModal.click();
        }
      })
      
    }
  }

}
