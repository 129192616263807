import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { MustMatch } from '../../_helpers/must-match.validator';

declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public registerForm: FormGroup;
  public submitted = false;
  public otpForm: FormGroup;
  public register_userId: any;
  public showSpinner: boolean = false;
  public showotpSpinner: boolean = false;
  public ErrorMessage: any;

  constructor(private formBuilder: FormBuilder, private service: UserService, private router: Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        // category: ['', Validators.required],
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z0-9_.+-]+@(?!gmail)(?!yahoo)(?!hotmail)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
        // number: ['', [ Validators.minLength(10)]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmpassword: ['', Validators.required]}, 
        {
          validator: MustMatch('password', 'confirmpassword')
      });
      // this.otpForm = this.formBuilder.group({
      //   number1: ['', Validators.required],
      //   number2: ['', Validators.required],
      //   number3: ['', Validators.required],
      //   number4: ['', Validators.required],
      // });
      // [Validators.required, Validators.minLength(10)]
  }

  get f() { return this.registerForm.controls; }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    if(this.registerForm.value.number.length > 9){
      event.preventDefault();
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    else{
      var formData = new FormData();
      formData.append('full_name', this.registerForm.value.name);
      // formData.append('mobile', this.registerForm.value.number);
      formData.append('email', this.registerForm.value.email);
      formData.append('password', this.registerForm.value.password);
      this.showSpinner = true;
      this.service.createUser(formData).subscribe((response : any) =>{
        if(response.status == 1){
          // this.service.successAlert(response.message);
          this.showSpinner = false;
          this.registerForm.reset();
          this.submitted = false;
          // this.register_userId = response.data.user_id;
          // this.service.sendNumber(response.data.user_id);
          document.getElementById("openModalButton").click();
          // this.router.navigateByUrl('/account/login')
        }
        else{
          this.submitted = false;
          // this.service.dangerAlert(response.message);
          this.showSpinner = false;
          this.ErrorMessage = response.message;
          let ErrorMEssageModal: HTMLElement = document.getElementById('errorMessage_register') as HTMLElement; 
          ErrorMEssageModal.click();
        }
      })
      
    }
  }

  // otpkeyPress(event: any) {
  //   const pattern = /[0-9\+\-\ ]/;

  //   let inputChar = String.fromCharCode(event.charCode);
  //   if (event.keyCode != 8 && !pattern.test(inputChar)) {
  //     event.preventDefault();
  //   }
  // }

  // otpKeyNumber1(event: any){
  //   if(this.otpForm.value.number1.length == 1){
  //     event.preventDefault();
  //   }
  // }
  // otpKeyNumber2(event: any){
  //   if(this.otpForm.value.number2.length == 1){
  //     event.preventDefault();
  //   }
  // }
  // otpKeyNumber3(event: any){
  //   if(this.otpForm.value.number3.length == 1){
  //     event.preventDefault();
  //   }
  // }
  // otpKeyNumber4(event: any){
  //   if(this.otpForm.value.number4.length == 1){
  //     event.preventDefault();
  //   }
  // }

  // otpSubmit() {
  //   var OTP = this.otpForm.value.number1 + '' + this.otpForm.value.number2 + '' + this.otpForm.value.number3 + '' + this.otpForm.value.number4 ;
  //   console.log(OTP)
  //   var formData = new FormData();
  //   formData.append('otp', OTP);
  //   formData.append('user_id', this.register_userId);
  //   this.showotpSpinner = true;
  //   this.service.otpVerify(formData).subscribe((response : any) =>{
  //     if(response.status == 1){
  //       this.service.successAlert(response.message);
  //       this.showotpSpinner = false;
  //       $('#exampleModal').hide();
  //       $('.modal-backdrop').hide();
  //       $('body').removeClass('modal-open');
  //       $('body').css('padding-right','0');
  //       this.router.navigateByUrl('/account/login')
  //     }
  //     else{
  //       this.service.dangerAlert(response.message);
  //       this.showotpSpinner = false;
  //     }
  //   })
  // }

  // resendOtp() {
  //   this.otpForm.reset();
  //   document.getElementById('exampleInputEmail11').focus();
  //   var formData = new FormData();
  //   formData.append('user_id', this.register_userId);
  //   this.service.otpResend(formData).subscribe((response : any) =>{
  //     if(response.status == 1){
  //       this.service.successAlert(response.message);
  //     }
  //     else{
  //       this.service.dangerAlert(response.message);
  //     }
  //   })
  // }

  // called(m, event){
  //   if(event.keyCode != 8){
  //     if(m == 'exampleInputEmail11'){
  //       document.getElementById('exampleInputEmail12').focus();
  //       } else if(m == 'exampleInputEmail12'){
  //       document.getElementById('exampleInputEmail13').focus();
  //       }
  //       else if(m == 'exampleInputEmail13'){
  //         document.getElementById('exampleInputEmail14').focus();
  //       }
  //   }
  //   }


     //update ur domains here




}
