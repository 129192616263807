import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { Router,NavigationExtras } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {
  public terms_content: any;
  constructor(private service: UserService, private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.privacyPolicy();
  }

   // Privacy Policy content
   privacyPolicy() {
    this.service.getTermsCondition().subscribe((response : any) =>{
      if(response.status == 1){
        this.terms_content = response.data;
      }})
  }

}
